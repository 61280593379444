
    import { Component, Vue } from 'vue-property-decorator';
    import CircleTag from '@/components/tags/CircleTag.vue';
    import { Country } from '@/enums/Country';
    import { useConfiguratorStore } from '@/store/configurator';

    @Component({
        components: {
            CircleTag,
        },
    })
    export default class TermChooser extends Vue {
        country!: any;

        $refs!: {
            sc: HTMLDivElement;
        };

        get configuratorStore() {
            return useConfiguratorStore();
        }

        mounted() {
            this.scrollToActiveTerm();
        }

        scrollToActiveTerm() {
            if (this.$el) {
                // get the active div element
                const elem = this.$el.querySelector('.rectangle-button.active') as HTMLDivElement;

                if (elem) {
                    // scroll it in view (approximately)
                    this.$refs.sc.scrollTo({ behavior: 'smooth', left: elem.offsetLeft - 100 });
                }
            }
        }

        beforeCreate() {
            this.country = Country;
        }
    }
