
    import { Component, Vue } from 'vue-property-decorator';
    import { IAdditionalFixture } from '@/interfaces/IAdditionalFixture';
    import { normalizeSvgViewBoxes } from '@/util/misc';
    import { useConfiguratorStore } from '@/store/configurator';
    import { useCustomerStore } from '@/store/customer';
    import { useGlobalStore } from '@/store/global';
    import { ConfigurationOption } from '@/enums/ConfigurationOption';

    @Component
    export default class AdditionalFixture extends Vue {
        itemsOpen = false;

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        hasAF(af: IAdditionalFixture): boolean {
            return Boolean(
                this.configuratorStore.priceRequest?.additionalFixtures?.find(
                    (item) => item.id === af.id,
                ),
            );
        }

        async onFixtureClick(af) {
            if (this.configuratorStore.priceRequest?.additionalFixtures === undefined) {
                throw new Error('additionalFixtures is undefined');
            }

            if (this.hasAF(af)) {
                for (const [
                    i,
                    item,
                ] of this.configuratorStore.priceRequest.additionalFixtures.entries()) {
                    if (item.id === af.id) {
                        // save the current additionalFixtures list for instalytics
                        const oldValue = [
                            ...this.configuratorStore.priceRequest.additionalFixtures,
                        ];

                        this.configuratorStore.priceRequest.additionalFixtures.splice(i, 1);
                        this.configuratorStore.sendToInstalytics(
                            ConfigurationOption.ADDITIONAL_FIXTURES,
                            oldValue,
                            this.configuratorStore.priceRequest.additionalFixtures,
                        );
                    }
                }
            } else {
                // save the current additionalFixtures list for instalytics
                const oldValue = [...this.configuratorStore.priceRequest.additionalFixtures];

                this.configuratorStore.priceRequest.additionalFixtures.push(af);
                this.configuratorStore.sendToInstalytics(
                    ConfigurationOption.ADDITIONAL_FIXTURES,
                    oldValue,
                    this.configuratorStore.priceRequest.additionalFixtures,
                );
            }

            await this.configuratorStore.retrievePrice();
        }

        mounted() {
            this.$nextTick(function () {
                // Code that will run only after the entire view has been rendered
                normalizeSvgViewBoxes(this.$el.querySelectorAll('span.svg > svg'));
            });
        }
    }
