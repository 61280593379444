
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import lozad from 'lozad';
    import { ILazyImageStyle } from '@/interfaces/ILazyImageStyle';

    @Component
    export default class LazyImage extends Vue {
        @Prop({ default: '#fff' }) backgroundColor!: string;
        @Prop({ default: null }) width!: number;
        @Prop({ default: null }) height!: number;
        @Prop({ default: null }) lazySrc!: string;
        @Prop({ default: null }) lazySrcset!: string;
        @Prop({ default: '' }) title!: string;

        loading = true;

        get aspectRatio() {
            // Calculate the aspect ratio of the image
            // if the width and the height are given.
            if (!this.width || !this.height) {
                return null;
            }

            return (this.height / this.width) * 100;
        }

        get style() {
            // The background color is used as a
            // placeholder while loading the image.
            // You can use the dominant color of the
            // image to improve perceived performance.
            // See: https://manu.ninja/dominant-colors-for-lazy-loading-images/
            const style: ILazyImageStyle = { backgroundColor: this.backgroundColor };

            if (this.width) {
                style.width = `${this.width}px`;
            }

            // If the image is still loading and an
            // aspect ratio could be calculated, we
            // apply the calculated aspect ratio by
            // using padding top.
            const applyAspectRatio = this.loading && this.aspectRatio;
            if (applyAspectRatio) {
                // Prevent flash of unstyled image
                // after the image is loaded.
                style.height = 0;
                // Scale the image container according
                // to the aspect ratio.
                style.paddingTop = `${this.aspectRatio}%`;
            }

            return style;
        }

        mounted() {
            // As soon as the <img> element triggers
            // the `load` event, the loading state is
            // set to `false`, which removes the apsect
            // ratio we've applied earlier.
            const setLoadingState = () => {
                this.loading = false;
            };

            this.$el.addEventListener('load', setLoadingState);

            // We remove the event listener as soon as
            // the component is destroyed to prevent
            // potential memory leaks.
            this.$once('hook:destroyed', () => {
                this.$el.removeEventListener('load', setLoadingState);
            });

            // We initialize Lozad.js on the root
            // element of our component.
            const observer = lozad('.id-lazy');
            observer.observe();
        }
    }
