import { defineStore } from 'pinia';
import { GlobalStep } from '@/enums/GlobalStep';
import { Country } from '@/enums/Country';
import { ref, watch } from 'vue';
import { ISalesChannel } from '@/interfaces/ISalesChannel';
import { IBrand } from '@/interfaces/IBrand';
import { IVehicleClass } from '@/interfaces/car/IVehicleClass';
import { IVehicleType } from '@/interfaces/car/IVehicleType';
import { Cooperation } from '@/enums/Cooperation';
import { getSalesChannels } from '@/api/customer';
import { getBrands, getGlobals, getVehicleClass, getVehicleType } from '@/api/car';
import { getPriceDisplay } from '@/util/currency';
import { IStepNavigationInteraction } from '@/interfaces/instalytics/interaction';
import { IMake } from '@/interfaces/car/IMake';
import { useConfiguratorStore } from '@/store/configurator';
import { useCustomerStore } from '@/store/customer';
import { addStepNavigationInteraction } from '@/api/instalytics';
import { getNet } from '@/util/misc';
import { getShowSurvey, getSurvey, postSurveyAnswer } from '@/api/survey';
import { ISurvey } from '@/interfaces/ISurvey';
import { ISurveyAnswer } from '@/interfaces/ISurveyAnswer';
import { ISurveyShown } from '@/interfaces/ISurveyShown';

export const useGlobalStore = defineStore(
    'global',
    () => {
        // give access to configurator and customer stores here
        const configuratorStore = useConfiguratorStore();
        const customerStore = useCustomerStore();

        const country = ref<Country | undefined>(undefined);
        const showTutorial = ref<boolean>(true);
        const iaInterval = ref<number>(14);
        const qaInterval = ref<number>(75);
        const salesChannels = ref<ISalesChannel[]>([]);
        const brands = ref<IBrand[]>([]);
        const vehicleClasses = ref<IVehicleClass[]>([]);
        const vehicleTypes = ref<IVehicleType[]>([]);
        const currentStep = ref<GlobalStep>(GlobalStep.CONFIGURATION);
        const survey = ref<ISurvey | undefined>(undefined);
        const surveysDismissed = ref<number[]>([]);
        const cooperation = ref<Cooperation | undefined>(undefined);
        const lastURL = ref('');

        /**
         * Send data to instalytics.
         *
         * @param oldStep
         * @param newStep
         */
        async function sendToAnalytics(oldStep: GlobalStep, newStep: GlobalStep) {
            if (!country.value || !configuratorStore.car) {
                throw new Error('Cannot create instalytics data');
            }

            const interaction: IStepNavigationInteraction = {
                email: customerStore.customer.mail_address,
                country: country.value,
                make: {
                    id: (configuratorStore.car.model_variant.model.make as IMake).id,
                    name: (configuratorStore.car.model_variant.model.make as IMake).name,
                },
                car: {
                    id: configuratorStore.car.id,
                    name: `${configuratorStore.car.model_variant.model.name} ${
                        configuratorStore.car.model_variant.name || ''
                    }`,
                    model_year: configuratorStore.car.model_year,
                    vehicle_state: configuratorStore.car.vehicle_state,
                },
                old_step: oldStep,
                new_step: newStep,
            };

            return await addStepNavigationInteraction(interaction);
        }

        watch(currentStep, (newStep, oldStep) => {
            if (newStep !== GlobalStep.CONGRATULATIONS) {
                sendToAnalytics(oldStep, newStep);
            }
        });

        /**
         * Function to display a price based on chosen country.
         *
         * @param price
         */
        function displayPrice(price: number): string {
            if (!country.value) {
                throw new Error('Country is undefined!');
            }

            return getPriceDisplay(country.value, price);
        }

        /**
         * Function to calculate the net value.
         *
         * @param price
         */
        function getNetValue(price: number): number {
            if (!country.value) {
                throw new Error('Country is undefined!');
            }

            return getNet(price, country.value);
        }

        /**
         * Function to get the tax abbreviations (e.g. 'USt', 'MWST',...) depending on chosen country.
         */
        function taxAbbr(): string {
            if (!country.value) {
                throw new Error('Country is undefined!');
            }

            switch (country.value) {
                case Country.DE:
                case Country.AT:
                    return 'USt';
                case Country.CH:
                    return 'MWST';
                case Country.FR:
                    return 'TVA';
            }

            return 'VAT';
        }

        /**
         * Retrieve the INSTARDRIVE sales channels
         */
        async function retrieveSalesChannels() {
            if (!country.value) {
                throw new Error('country is undefined');
            }

            const resp = await getSalesChannels(country.value);

            if (resp.parsedBody) {
                salesChannels.value = resp.parsedBody;
            } else {
                throw new Error('cannot get sales channels');
            }
        }

        /**
         * Retrieve the 'used' car brands.
         */
        async function retrieveBrands() {
            if (!country.value) {
                throw new Error('country is undefined');
            }

            const resp = await getBrands(country.value);

            if (resp.parsedBody) {
                brands.value = resp.parsedBody;
            } else {
                throw new Error('cannot get used brands');
            }
        }

        /**
         * Retrieve the 'used' car classes.
         */
        async function retrieveVehicleClasses() {
            if (!country.value) {
                throw new Error('country is undefined');
            }

            const resp = await getVehicleClass(country.value);

            if (resp.parsedBody) {
                vehicleClasses.value = resp.parsedBody;
            } else {
                throw new Error('cannot get used car classes');
            }
        }

        /**
         * Retrieve the 'used' car types.
         */
        async function retrieveVehicleTypes() {
            if (!country.value) {
                throw new Error('country is undefined');
            }

            const resp = await getVehicleType(country.value);

            if (resp.parsedBody) {
                vehicleTypes.value = resp.parsedBody;
            } else {
                throw new Error('cannot get used car types');
            }
        }

        /**
         * Retrieve the default instantly available interval.
         */
        async function retrieveIntervals() {
            if (!country.value) {
                throw new Error('no country set');
            }

            const globals = await getGlobals(country.value);

            if (globals.parsedBody) {
                iaInterval.value = globals.parsedBody.instantly_available;
                qaInterval.value = globals.parsedBody.quickly_available;
            } else {
                throw new Error('cannot get intervals');
            }
        }

        /**
         * Retrieve active surveys.
         */
        async function retrieveSurveys() {
            if (!country.value) {
                throw new Error('no country set');
            }

            const surveyResp = await getSurvey(country.value);

            if (surveyResp.parsedBody && Object.keys(surveyResp.parsedBody).length !== 0) {
                survey.value = surveyResp.parsedBody;
                /** Sorting of questions */
                if (survey.value) {
                    survey.value.entries.sort((a, b) => a.sequence - b.sequence);
                    survey.value.entries.forEach((entry) => {
                        entry.questions.sort((a, b) => a.sequence - b.sequence);
                    });
                }
            }
        }

        /**
         * Retrieve if current survey should be shown.
         */
        async function checkIfSurveyShouldBeShown(
            leadEmail: string,
            surveyID: number,
        ): Promise<ISurveyShown> {
            const surveyShownResp = await getShowSurvey(leadEmail, surveyID);

            if (surveyShownResp.parsedBody) {
                return surveyShownResp.parsedBody;
            } else {
                throw new Error('cannot get if survey should be shown');
            }
        }

        /**
         * Send Survey Answer.
         */
        async function sendSurveyAnswer(answer: ISurveyAnswer) {
            const surveyAnswerResp = await postSurveyAnswer(answer);

            if (surveyAnswerResp.parsedBody) {
                return surveyAnswerResp.parsedBody;
            } else {
                throw new Error('cannot send survey answer');
            }
        }

        /** Shows if price is negative -> for hiding stuff */
        function isPriceNegative() {
            if (configuratorStore.priceResult && customerStore.customer.is_company !== undefined) {
                return (
                    (customerStore.customer.is_company &&
                        configuratorStore.priceResult.fp_price.after < 0) ||
                    (!customerStore.customer.is_company &&
                        configuratorStore.priceResult.fp_price.pre < 0)
                );
            } else {
                false;
            }
        }

        return {
            country,
            showTutorial,
            salesChannels,
            cooperation,
            currentStep,
            brands,
            vehicleClasses,
            vehicleTypes,
            iaInterval,
            qaInterval,
            lastURL,
            survey,
            surveysDismissed,
            retrieveIntervals,
            retrieveSalesChannels,
            retrieveBrands,
            retrieveVehicleClasses,
            retrieveVehicleTypes,
            retrieveSurveys,
            checkIfSurveyShouldBeShown,
            sendSurveyAnswer,
            displayPrice,
            getNetValue,
            taxAbbr,
            isPriceNegative,
        };
    },
    {
        /**
         *  Establish the pinia persistent storage.
         *  Store the following in localStorage:
         *
         *  - country
         *
         *  These fields should 'survive' a page refresh.
         *
         *  @see https://github.com/prazdevs/pinia-plugin-persistedstate
         */
        persist: {
            paths: ['country', 'showTutorial', 'surveysDismissed'],
        },
    },
);
