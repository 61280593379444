import { Country } from '@/enums/Country';
import { get, post, HttpResponse } from '@/api/base';
import { ISurveyAnswer } from '@/interfaces/ISurveyAnswer';

export async function getSurvey(c: Country): Promise<HttpResponse<any>> {
    return await get<any>(`${process.env.VUE_APP_IC_SERVICE_HOST}/surveys/${c}/`);
}

export async function getShowSurvey(
    leadEmail: string,
    surveyID: number,
): Promise<HttpResponse<any>> {
    return await post<any>(`${process.env.VUE_APP_IC_SERVICE_HOST}/surveys/show/`, {
        lead_email: leadEmail,
        survey_id: surveyID,
    });
}

export async function postSurveyAnswer(answer: ISurveyAnswer): Promise<HttpResponse<any>> {
    return await post<any>(`${process.env.VUE_APP_IC_SERVICE_HOST}/surveys/`, answer);
}
