
    import { Component, Vue } from 'vue-property-decorator';
    import { ExtraOption } from '@/enums/ExtraOption';
    import { Country } from '@/enums/Country';
    import { useGlobalStore } from '@/store/global';
    import { useConfiguratorStore } from '@/store/configurator';
    import { ConfigurationOption, convertOption } from '@/enums/ConfigurationOption';
    import { useCustomerStore } from '@/store/customer';
    import { VehicleState } from '@/enums/VehicleState';

    @Component
    export default class ExtraOptions extends Vue {
        extras: any;
        country: any;
        vehicleState = VehicleState;
        itemsOpen = true;

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        /**
         * Click handler for switching tire package.
         */
        async onTirePackageClick() {
            if (!this.configuratorStore.priceRequest) {
                throw new Error('invalid price request');
            }

            // save the current/old value for instalytics
            const oldValue = this.configuratorStore.priceRequest.tirePackage;

            this.configuratorStore.priceRequest.tirePackage =
                !this.configuratorStore.priceRequest.tirePackage;
            this.configuratorStore.sendToInstalytics(
                ConfigurationOption.TIRE_PACKAGE,
                oldValue,
                this.configuratorStore.priceRequest.tirePackage,
            );

            /**
             *  - retrieve price
             *  - re-retrieve optional prices (tire package could impact its prices)
             *  - update chosen additional fixture on price_request
             *  - retrieve price
             */
            await this.configuratorStore.retrievePrice();
            await this.configuratorStore.retrieveOptionalPrices();
            await this.configuratorStore.retrieveChargingEquip();
            await this.configuratorStore.updateAFS();
            await this.configuratorStore.retrievePrice();
        }

        /**
         *  Click handler for switching delivery.
         */
        async onDeliveryClick() {
            if (!this.configuratorStore.priceRequest) {
                throw new Error('invalid price request');
            }

            if (!this.configuratorStore.priceOptionals) {
                throw new Error('priceOptionals undefined');
            }

            // save the current/old value for instalytics
            const oldValue = Boolean(this.configuratorStore.priceRequest.delivery);

            if (this.configuratorStore.priceRequest.delivery === null) {
                this.configuratorStore.priceRequest.delivery =
                    this.configuratorStore.priceOptionals.delivery;
            } else {
                this.configuratorStore.priceRequest.delivery = null;
            }

            this.configuratorStore.sendToInstalytics(
                ConfigurationOption.DELIVERY,
                oldValue,
                Boolean(this.configuratorStore.priceRequest.delivery),
            );

            await this.configuratorStore.retrievePrice();
        }

        /**
         * Click handler for adding/removing an extra item.
         *
         * @param extra
         */
        async onExtraClick(extra: ExtraOption) {
            if (!this.configuratorStore.priceRequest) {
                throw new Error('invalid price request');
            }

            if (this.configuratorStore.priceRequest.extraOptions === undefined) {
                throw new Error('extra options undefined');
            }

            if (this.configuratorStore.hasExtraOption(extra)) {
                for (const [i, eo] of this.configuratorStore.priceRequest.extraOptions.entries()) {
                    if (eo.name === extra) {
                        this.configuratorStore.priceRequest.extraOptions?.splice(i, 1);

                        // send the extra option change to instalytics
                        this.configuratorStore.sendToInstalytics(
                            convertOption(eo.name as ExtraOption),
                            true,
                            false,
                        );
                    }
                }
            } else {
                const item = this.configuratorStore.priceOptionals?.extraOptions.find(
                    (item) => item.name === extra,
                );

                if (item) {
                    // add extra item to extra options
                    this.configuratorStore.priceRequest.extraOptions?.push(item);

                    // send the extra option change to instalytics
                    this.configuratorStore.sendToInstalytics(
                        convertOption(item.name as ExtraOption),
                        false,
                        true,
                    );
                }
            }
            await this.configuratorStore.retrievePrice();
        }

        get germanRetention(): number[] {
            if (
                this.configuratorStore.car &&
                this.configuratorStore.car.technicaldata.power_rating >= 100
            ) {
                return [1000, 500];
            }

            return [500, 250];
        }

        beforeCreate() {
            this.extras = ExtraOption;
            this.country = Country;
        }
    }
