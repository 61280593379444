import { render, staticRenderFns } from "./CarImage.vue?vue&type=template&id=31b83988&scoped=true&"
import script from "./CarImage.vue?vue&type=script&lang=ts&"
export * from "./CarImage.vue?vue&type=script&lang=ts&"
import style0 from "./CarImage.vue?vue&type=style&index=0&id=31b83988&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31b83988",
  null
  
)

export default component.exports