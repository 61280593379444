import { get, HttpResponse, post } from '@/api/base';
import { ICustomerAddOrUpdateResponse } from '@/interfaces/ICustomerAddOrUpdateResponse';
import { ICustomer } from '@/interfaces/ICustomer';
import { IChargingEquipment } from '@/interfaces/order/IChargingEquipment';
import { Country } from '@/enums/Country';
import { ISalesChannel } from '@/interfaces/ISalesChannel';
import { IVerifyEmailResponse } from '@/interfaces/IVerifyEmailResponse';

export async function addOrUpdateCustomer(
    data: ICustomer,
): Promise<HttpResponse<ICustomerAddOrUpdateResponse>> {
    return await post<ICustomerAddOrUpdateResponse>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/offerstore/customer/`,
        data,
    );
}

export async function getChargingEquip(
    carId: number,
    c: Country,
): Promise<HttpResponse<IChargingEquipment[]>> {
    return await get<IChargingEquipment[]>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/service/public/charging-equipment/${c}/${carId}/`,
    );
}

export async function getSalesChannels(country: Country): Promise<HttpResponse<ISalesChannel[]>> {
    return await get<ISalesChannel[]>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/offerstore/saleschannels/${country}/`,
    );
}

export async function verifyEmail(
    mailAddress: string,
): Promise<HttpResponse<IVerifyEmailResponse>> {
    return await get<IVerifyEmailResponse>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/offerstore/email/verify/${mailAddress}/`,
    );
}
