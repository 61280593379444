
    import { Component, Vue } from 'vue-property-decorator';
    import { getCO2Barrels } from '@/util/misc';
    import i18n from '@/i18n';
    import { useConfiguratorStore } from '@/store/configurator';

    @Component
    export default class InfoBox extends Vue {
        get configuratorStore() {
            return useConfiguratorStore();
        }

        get CO2Barrels() {
            if (!this.configuratorStore.car || !this.configuratorStore.config) {
                return '';
            }

            return getCO2Barrels(this.configuratorStore.car, this.configuratorStore.config);
        }

        get availabilitySubText() {
            return i18n.tc('messages.plus_registration_preparation');
        }
    }
