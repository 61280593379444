
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class CircleProgress extends Vue {
        @Prop({ default: 1 }) currentStep?;
        @Prop({ default: 4 }) totalSteps?;

        step() {
            this.currentStep++;

            if (this.currentStep > this.totalSteps) {
                this.currentStep = 1;
            }
        }

        get circle() {
            return (
                (((this.currentStep + 1) * (100 / this.totalSteps)) / 100) * 100 * Math.PI + ',9999'
            );
        }
    }
