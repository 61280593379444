import { parseISO } from 'date-fns';

// commonly used iso date regex: https://regex101.com/r/Xpseru/2
const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

/**
 * Checks for an ISO date string.
 *
 * @param value
 * @return true or false
 */
export function isIsoDateString(value: string): boolean {
    return Boolean(value && isoDateFormat.test(value));
}

/**
 * Inspired by https://stackoverflow.com/questions/65692061/casting-dates-properly-from-an-api-response-in-typescript
 * Converts date fields to javascript date objects via date-fns' parseISO() function,
 *
 * @param body any response
 */
export function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== 'object') {
        return body;
    }

    for (const key of Object.keys(body)) {
        const value = body[key];

        if (isIsoDateString(value)) {
            body[key] = parseISO(value);
        } else if (typeof value === 'object') {
            handleDates(value);
        }
    }
}
