import { defineStore } from 'pinia';
import { ICarFull } from '@/interfaces/car/ICarFull';
import { IConfiguration } from '@/interfaces/IConfiguration';
import { IPriceResult } from '@/interfaces/IPriceResult';
import { computed, ref, watch } from 'vue';
import { getCar } from '@/api/car';
import router from '@/router';
import { check404, getDeliveryString } from '@/util/misc';
import { useGlobalStore } from '@/store/global';
import { buildPriceReq } from '@/util/car';
import i18n from '@/i18n';
import { IPriceRequest } from '@/interfaces/IPriceRequest';
import { IAdditionalFixture } from '@/interfaces/IAdditionalFixture';
import { getChargingEquip } from '@/api/customer';
import { IPriceOptionals } from '@/interfaces/IPriceOptionals';
import { getOptionalPrices, getPrice } from '@/api/price';
import { ExtraOption } from '@/enums/ExtraOption';
import { IConfiguratonInteraction, NewValue, OldValue } from '@/interfaces/instalytics/interaction';
import { useCustomerStore } from '@/store/customer';
import { ConfigurationOption } from '@/enums/ConfigurationOption';
import { IMake } from '@/interfaces/car/IMake';
import { GlobalStep } from '@/enums/GlobalStep';
import { addConfigurationInteraction } from '@/api/instalytics';

export const useConfiguratorStore = defineStore('configurator', () => {
    // give access to global and customer stores here
    const globalStore = useGlobalStore();
    const customerStore = useCustomerStore();

    const car = ref<ICarFull | undefined>(undefined);
    const carIsLoading = ref(false);
    const config = ref<IConfiguration>({
        color: undefined,
        fixture: undefined,
        term: 24,
        kilometerPerYear: 5,
        initialPaymentAmount: undefined,
        canton: undefined,
        facilitationSpecials: undefined,
    });
    const priceRequest = ref<IPriceRequest | undefined>(undefined);
    const priceOptionals = ref<IPriceOptionals | undefined>(undefined);
    const priceResult = ref<IPriceResult | undefined>(undefined);

    // is used to manually ignore config changes for instalytics
    // e.g. config changes via code rather from user (Configurator.vue mounted hook e.g.)
    const ignoreChangesInstalytics = ref<boolean>(false);

    /**
     * Event handler when config has changed: retrieve prices and optional prices stuff.
     */
    async function onConfigChanged() {
        // Only retrieve price when config color is available in fixture.
        // Because of edge case when color is not available and switch to other fixture (see FixtureChooser.vue)
        const color = config.value.color;
        const fixtureLevel = config.value.fixture;

        if (!color || !fixtureLevel) {
            throw new Error('color and/or fixturelevel is undefined');
        }

        if (color.fixture_levels.includes(fixtureLevel)) {
            await retrievePrice();

            await retrieveOptionalPrices();
            await retrieveChargingEquip();
            await updateAFS();
            await retrievePrice();
        }
    }

    /**
     * Send data to instalytics.
     *
     * @param option
     * @param oldValue
     * @param newValue
     */
    async function sendToInstalytics(
        option: ConfigurationOption,
        oldValue: OldValue,
        newValue: NewValue,
    ) {
        if (
            !globalStore.country ||
            !car.value ||
            !config.value.fixture ||
            !config.value.color ||
            !config.value.initialPaymentAmount
        ) {
            throw new Error('Cannot create instalytics data');
        }

        const interaction: IConfiguratonInteraction = {
            origin_step: GlobalStep.CONFIGURATION,
            email: customerStore.customer.mail_address,
            country: globalStore.country,
            make: {
                id: (car.value.model_variant.model.make as IMake).id,
                name: (car.value.model_variant.model.make as IMake).name,
            },
            car: {
                id: car.value.id,
                name: `${car.value.model_variant.model.name} ${car.value.model_variant.name || ''}`,
                model_year: car.value.model_year,
                vehicle_state: car.value.vehicle_state,
            },
            option: option,
            old_value: oldValue,
            new_value: newValue,
            current_config: {
                fixture_level: config.value.fixture,
                color: {
                    id: config.value.color.id,
                    name: config.value.color.name,
                },
                term: config.value.term,
                km_mi: config.value.kilometerPerYear,
                initial_payment: parseInt(config.value.initialPaymentAmount.amount.toString()),
            },
        };

        await addConfigurationInteraction(interaction);
    }

    watch(
        () => config.value.color,
        async (newValue, oldValue) => {
            // ignore initial values
            if (oldValue !== undefined && !ignoreChangesInstalytics.value) {
                await onConfigChanged();

                if (!newValue) {
                    throw new Error('Cannot send to instalytics, newValue undefined.');
                }

                sendToInstalytics(
                    ConfigurationOption.COLOR,
                    { id: oldValue.id, name: oldValue.name },
                    { id: newValue.id, name: newValue.name },
                );
            }
        },
    );

    watch(
        () => config.value.fixture,
        async (newValue, oldValue) => {
            if (oldValue !== undefined && !ignoreChangesInstalytics.value) {
                await onConfigChanged();

                if (!newValue) {
                    throw new Error('Cannot send to instalytics, newValue undefined.');
                }

                sendToInstalytics(ConfigurationOption.FIXTURE_LEVEL, oldValue, newValue);
            }
        },
    );

    watch(
        () => config.value.term,
        async (newValue, oldValue) => {
            if (oldValue !== undefined && !ignoreChangesInstalytics.value) {
                await onConfigChanged();

                if (!newValue) {
                    throw new Error('Cannot send to instalytics, newValue undefined.');
                }

                sendToInstalytics(ConfigurationOption.TERM, oldValue, newValue);
            }
        },
    );

    watch(
        () => config.value.kilometerPerYear,
        async (newValue, oldValue) => {
            if (oldValue !== undefined && !ignoreChangesInstalytics.value) {
                await onConfigChanged();

                if (!newValue) {
                    throw new Error('Cannot send to instalytics, newValue undefined.');
                }

                sendToInstalytics(ConfigurationOption.KILOMETRAGE, oldValue, newValue);
            }
        },
    );

    watch(
        () => config.value.initialPaymentAmount,
        async (newValue, oldValue) => {
            if (oldValue !== undefined && !ignoreChangesInstalytics.value) {
                await onConfigChanged();

                if (!newValue) {
                    throw new Error('Cannot send to instalytics, newValue undefined.');
                }

                sendToInstalytics(
                    ConfigurationOption.INITIAL_PAYMENT,
                    oldValue.amount,
                    newValue.amount,
                );
            }
        },
    );

    watch(
        () => config.value.canton,
        async () => {
            if (!ignoreChangesInstalytics.value) {
                await onConfigChanged();
            }
        },
    );

    async function retrieveCar() {
        carIsLoading.value = true;

        if (!globalStore.country) {
            throw new Error('country is undefined');
        }

        const carResp = await getCar(router.currentRoute.params.slug, globalStore.country);
        check404(carResp);

        if (carResp.parsedBody) {
            car.value = carResp.parsedBody;
            carIsLoading.value = false;
        } else {
            throw new Error('cannot get car');
        }
    }

    async function retrievePrice() {
        ignoreChangesInstalytics.value = true;

        if (!globalStore.country) {
            throw new Error('country is undefined');
        }

        if (!car.value) {
            throw new Error('car is undefinfed');
        }

        priceRequest.value = buildPriceReq(
            car.value,
            config.value,
            globalStore.country,
            Boolean(customerStore.customer.is_company),
            priceRequest.value,
        );

        const priceResponse = await getPrice(priceRequest.value);

        if (priceResponse.parsedBody) {
            priceResult.value = priceResponse.parsedBody;
        } else {
            throw new Error('cannot get price');
        }

        ignoreChangesInstalytics.value = false;
    }

    // deliveryString getter
    const deliveryString = computed(() => {
        if (priceResult.value && globalStore.iaInterval !== undefined) {
            return getDeliveryString(priceResult.value.dt.days, globalStore.iaInterval);
        }
        return i18n.tc('messages.on_request');
    });

    /**
     * Checks if the given extra option items was chosen.
     * @param extra extra options item
     */
    function hasExtraOption(extra: ExtraOption): boolean {
        return Boolean(
            priceRequest.value?.extraOptions?.find((extraItem) => extraItem.name === extra),
        );
    }

    /**
     * Get the extra option's price.
     *
     * @param extra
     * @param ignoreBusinessMode if it's true, business mode check is disabled (to get the net prices)
     */
    function getExtraOptionsPrice(extra: ExtraOption, ignoreBusinessMode = false): number {
        const item = priceOptionals.value?.extraOptions.find((item) => item.name === extra);

        if (item) {
            if (!ignoreBusinessMode && customerStore.customer.is_company) {
                return item.increase_in_price.after;
            }

            return item.increase_in_price.pre;
        }

        throw new Error('cannot get extra option prices.');
    }

    /**
     * Update the additional fixture prices
     */
    function updateAFS() {
        if (!priceRequest.value) {
            throw new Error('Invalid price request.');
        }

        if (!priceOptionals.value) {
            throw new Error('priceOptionals is undefined.');
        }

        if (priceRequest.value.additionalFixtures) {
            const updatedAfs: IAdditionalFixture[] = [];

            for (const af of priceRequest.value.additionalFixtures) {
                const savedAf = priceOptionals.value.additionalFixtures.find(
                    (item) => item.id === af.id,
                );

                if (savedAf) {
                    updatedAfs.push(savedAf);
                }
            }

            priceRequest.value.additionalFixtures = updatedAfs;
        }
    }

    /**
     * Retrieve the optional prices and store it.
     *  - additional fixture saved prices
     *  - extra options saved prices
     *  - delivery price
     */
    async function retrieveOptionalPrices() {
        if (!priceRequest.value) {
            throw new Error('Invalid price request.');
        }

        // set includeDetails to false, not necessary/useful for optional prices request
        // set the additionalFixtures to empty list
        // copy it, don't really change the object
        const pr = { ...priceRequest.value, includeDetails: false, additionalFixtures: [] };
        const optionPricesResponse = await getOptionalPrices(pr);

        if (optionPricesResponse.parsedBody) {
            priceOptionals.value = {
                additionalFixtures: optionPricesResponse.parsedBody.afs,
                extraOptions: optionPricesResponse.parsedBody.eos,
                delivery: optionPricesResponse.parsedBody.delivery,
                chargingEquipment: [],
            };
        } else {
            throw new Error('Cannot retrieve optional prices.');
        }
    }

    /**
     * Retrieve the available charging equipments by car id.
     */
    async function retrieveChargingEquip() {
        if (!priceRequest.value) {
            throw new Error('Invalid price request.');
        }

        if (!globalStore.country) {
            throw new Error('Country is undefined.');
        }

        const chargingEquipResponse = await getChargingEquip(
            priceRequest.value.carId,
            globalStore.country,
        );

        if (chargingEquipResponse.parsedBody) {
            if (!priceOptionals.value) {
                throw new Error('cannot write charging equip, priceOptionals undefined');
            }

            // Added sorting for special INSTADRIVE equip so it is first
            chargingEquipResponse.parsedBody.sort((a, b) => {
                return b.description.toLowerCase().includes('instadrive') ? 1 : -1;
            });
            priceOptionals.value.chargingEquipment = chargingEquipResponse.parsedBody;
        } else {
            throw new Error('Cannot retrieve charging equipments.');
        }
    }

    return {
        car,
        carIsLoading,
        config,
        priceRequest,
        priceOptionals,
        priceResult,
        hasExtraOption,
        getExtraOptionsPrice,
        retrieveCar,
        retrievePrice,
        retrieveOptionalPrices,
        retrieveChargingEquip,
        updateAFS,
        deliveryString,
        ignoreChangesInstalytics,
        sendToInstalytics,
    };
});
