// import the router's component hooks
// @TODO: doesn't work
import '@/router/component-hooks';

import 'whatwg-fetch';
import 'reflect-metadata';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

// import main stylesheet for all components
import '@/assets/style/main.scss';

import Vue from 'vue';
import App from './App.vue';
import router from './router';

import * as Sentry from '@sentry/vue';
import posthog from 'posthog-js';
import posthogPlugin from '@/plugins/posthog';
import VueGtm from '@gtm-support/vue2-gtm';
import LoadScript from 'vue-plugin-load-script';
import VueRouter from 'vue-router';

import Buefy from 'buefy';
import VueScreen from 'vue-screen';
import i18n from './i18n';

import VueCalendly from 'vue-calendly';

// import form field validators globally
import '@/form-validation';

import { initCookieMgmt } from '@/util/cookiefirst';
import { marked } from 'marked';
import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

// plugin usage is global, assign it
// @ts-ignore
Vue.use(LoadScript);

// init posthog
Vue.use(posthogPlugin);

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: 'https://6a86925605414eaa8d81a8c09ed672f4@sentry.io/1886744',
        environment: process.env.VUE_APP_ENV,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
            new posthog.SentryIntegration(posthog, 'instadrive', 1886744),
        ],
        tracesSampleRate: 0.1,

        // capture Replay for 10% of all sessions,
        // plus for 20% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 0.2,
    });

    if (process.env.VUE_APP_ENV === 'production' || process.env.VUE_APP_ENV === 'staging') {
        // documentation: https://github.com/gtm-support/vue-gtm/tree/vue2-gtm
        Vue.use(VueGtm, {
            id: process.env.VUE_APP_GTM_ID,
            compatibility: false,
            debug: process.env.VUE_APP_ENV !== 'production',
            loadScript: true,
            vueRouter: router,
            trackOnNextTick: false,
            enabled: false, // disable on init
        });

        // build the site name (without scheme) and the api key
        const cookieFirstSiteSlug = process.env.VUE_APP_BASE_URL.replace('https://', '');
        const cookieFirstApiKey = `${cookieFirstSiteSlug}-${process.env.VUE_APP_COOKIEFIRST_API_KEY}`;

        // build the cookiefirst parameter string
        let cookieFirstParams = 'language=inherit';

        if (process.env.VUE_APP_ENV === 'production') {
            // silent the script in production env
            cookieFirstParams += '&silent-mode=true';
        }

        // @ts-ignore
        // load the cookiefirst banner script
        Vue.loadScript(
            `https://consent.cookiefirst.com/sites/${cookieFirstApiKey}/consent.js?${cookieFirstParams}`,
        ).then(() => {
            // init the cookie managment after initial cookiefirst script was loaded.
            // this loads further scripts with document.write, so we have to do the following...
            initCookieMgmt(Vue.prototype.$posthog);
        });
    }
}

// set default markdown renderer options
marked.setOptions({
    headerIds: false,
});

Vue.config.productionTip = false;

// attach Buefy Components to global Vue
Vue.use(Buefy);

// attach VueScreen to global vue (using default bulma breakpoints).
Vue.use(VueScreen, 'bulma');

// attach pinia store plugin
Vue.use(PiniaVuePlugin);

// create pinia store
const pinia = createPinia();

Vue.use(VueRouter);

Vue.use(VueCalendly);

// add the persisted plugin to pinia
pinia.use(piniaPluginPersistedstate);

new Vue({
    router,
    i18n,
    pinia,
    render: (h) => h(App),
}).$mount('#app');
