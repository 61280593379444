
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { useGlobalStore } from '@/store/global';
    import { useCustomerStore } from '@/store/customer';

    @Component
    export default class CrossPrice extends Vue {
        @Prop() newPrice!: number;
        @Prop() oldPrice!: number;

        get globalStore() {
            return useGlobalStore();
        }

        get customerStore() {
            return useCustomerStore();
        }
    }
