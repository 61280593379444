import { OrderBy } from '@/enums/OrderBy';
import { IConfiguration } from '@/interfaces/IConfiguration';
import { getName as getCarName } from '@/util/car';
import { ICar } from '@/interfaces/car/ICar';
import { ICarFull } from '@/interfaces/car/ICarFull';
import i18n from '@/i18n';
import { IColor } from '@/interfaces/car/IColor';
import { FixtureLevel } from '@/enums/FixtureLevel';
import { ICanton } from '@/interfaces/ICanton';
import { IPriceRequest } from '@/interfaces/IPriceRequest';
import { useConfiguratorStore } from '@/store/configurator';
import { Gender } from '@/enums/Gender';
import { Route } from 'vue-router';
import router from '@/router';
import { nextTick } from 'vue';
import { Country } from '@/enums/Country';

/**
 * Calculate the co2 barrels.
 *
 * @param car
 * @param config
 */
export function getCO2Barrels(car: ICarFull, config: IConfiguration) {
    // Jahreskilometer / 12 * Vertragslaufzeit in Monaten / 1.000.000
    const b = (((config.kilometerPerYear * 1000 * car.co2_gram) / 12) * config.term) / 1000000;

    return b.toLocaleString(i18n.locale);
}

/**
 * Check for 404 status (response).
 *
 * @param resp
 */
export function check404(resp: Response) {
    if (resp.status === 404) {
        router.push({ name: 'not-found' });
    }
}

/**
 * Build the delivery String.
 * Same logic as on server.
 * @TODO: maybe get rid of this and retrieve the string.
 *
 * @param dtDays
 * @param iaInterval
 */
export function getDeliveryString(dtDays: number | undefined | null, iaInterval: number): string {
    if (dtDays === undefined || dtDays === null) {
        return i18n.tc('messages.on_request');
    }

    if (dtDays <= iaInterval) {
        return i18n.tc('words.immediately');
    }

    if (dtDays < 30) {
        // delivery time less than a month, use weeks
        const weeks = Math.round(dtDays / 7);

        return weeks === 1
            ? `${weeks} ${i18n.tc('words.week', 1)}`
            : `${weeks} ${i18n.tc('words.week', 2)}`;
    }

    // default case: use months
    const months = Math.round(dtDays / 30);

    return months === 1
        ? `${months} ${i18n.tc('words.month', 1)}`
        : `${months} ${i18n.tc('words.month', 2)}`;
}

/**
 * Check if given car/color/fixture combination is in given interval.
 *
 * 1) Default delivery time overrides all -> true for all combinations.
 * 2) Default delivery time not in interval -> check if ccfa combination is in interval.
 *
 * @param color color object
 * @param fixtureLevel fixture level
 * @param car car object
 * @param interval interval in days
 * @returns true/false
 */
export function isDeliveryTimeInInterval(
    color: IColor,
    fixtureLevel: FixtureLevel,
    car: ICarFull,
    interval: number,
): boolean {
    if (car.default_delivery_time !== null && car.default_delivery_time <= interval) {
        // default delivery time is already in interval, immediately return true
        return true;
    }

    if (car.car_color_fixture_availabilities && car.car_color_fixture_availabilities.length > 0) {
        // ccfas found, get the single object by comparing color and fixture level
        const ccfaAvailable = car.car_color_fixture_availabilities.find(
            (item) => item.color === color.id && item.fixture_level === fixtureLevel,
        );

        if (
            ccfaAvailable &&
            ccfaAvailable.delivery_time !== null &&
            ccfaAvailable.delivery_time <= interval
        ) {
            // ccfa object was found, delivery time is set, and it is in interval, return true for that
            return true;
        }
    }

    // default case: false
    return false;
}

/**
 * Check for immediate availabilty by given car, color and fixture level.
 * Check if availability is in given interval (either immediately or quickly).
 * By given car, color fixture level and interval in days.
 *
 * @param car the car
 * @param c color
 * @param f fixture level
 * @param interval the interval in days
 * @return true/false whether it's in interval or not
 */
export function isInAvailabilityInterval(
    car: ICarFull,
    c: IColor,
    f: FixtureLevel,
    interval: number,
): boolean {
    let isDefaultIa = false;
    let overridesIA: boolean | undefined;

    // 1) check for default delivery time
    if (car.default_delivery_time !== null && car.default_delivery_time <= interval) {
        isDefaultIa = true;
    }

    // 2) check for overwritten combinations
    if (car.car_color_fixture_availabilities) {
        const cfAvail = car.car_color_fixture_availabilities.find(
            (item) => item.color === c.id && item.fixture_level === f,
        );

        // is either a boolean or undefined
        // delivery_time could be 'null', so there is no override possible
        overridesIA =
            cfAvail && cfAvail.delivery_time !== null && cfAvail.delivery_time <= interval;
    }

    if (overridesIA === undefined) {
        // there is no overwritten availability, return default one
        return isDefaultIa;
    }

    // obviously there is an overwritten availability
    return overridesIA;
}

/**
 * Build the text/label for kilometer/miles per year value.
 *
 * @param km
 */
export function buildDistancesText(km: number) {
    return `${Math.round(km * 1000).toLocaleString(i18n.locale)} ${i18n.tc('units.distance')}`;
}

/**
 * Sort the car list by given sort order.
 *
 * @param orderby
 * @param carList
 */
export function sortCarList(orderby: OrderBy, carList: ICar[]): void {
    switch (orderby) {
        case OrderBy.POPULARITY:
            carList.sort((a, b) => {
                // Check if promo and when both then promo order
                if (a.is_promo && b.is_promo) {
                    if (a.promo_order && b.promo_order) return a.promo_order - b.promo_order;
                    else return -1;
                } else if (a.is_promo && !b.is_promo) {
                    return -1;
                } else if (!a.is_promo && b.is_promo) {
                    return 1;
                }

                // check for stickerprice
                if (a.stickerpricedata && b.stickerpricedata) {
                    return a.stickerpricedata.price - b.stickerpricedata.price;
                } else if (a.stickerpricedata) {
                    return a.stickerpricedata.price - b.dynamic_from_price;
                } else if (b.stickerpricedata) {
                    return a.dynamic_from_price - b.stickerpricedata.price;
                } else {
                    // else normal price sorting and fallback name sorting
                    return (
                        a.dynamic_from_price - b.dynamic_from_price ||
                        getCarName(a).localeCompare(getCarName(b))
                    );
                }
            });
            break;
        case OrderBy.DELIVERY_TIME:
            carList.sort((a, b) => {
                // null/undefined values should come last
                // @see https://stackoverflow.com/questions/29829205/sort-an-array-so-that-null-values-always-come-last
                if (
                    a.min_delivery_time_combination.days === null ||
                    a.min_delivery_time_combination.days === undefined
                ) {
                    return 1;
                }

                if (
                    b.min_delivery_time_combination.days === null ||
                    b.min_delivery_time_combination.days === undefined
                ) {
                    return -1;
                }

                if (a.min_delivery_time_combination.days === b.min_delivery_time_combination.days) {
                    // they are equal, use lexical sorting
                    return getCarName(a).localeCompare(getCarName(b));
                }

                return a.min_delivery_time_combination.days - b.min_delivery_time_combination.days;
            });
            break;
        case OrderBy.FROM_PRICE:
            carList.sort((a, b) => {
                if (a.stickerpricedata && b.stickerpricedata) {
                    return a.stickerpricedata.price - b.stickerpricedata.price;
                } else if (a.stickerpricedata) {
                    return a.stickerpricedata.price - b.dynamic_from_price;
                } else if (b.stickerpricedata) {
                    return a.dynamic_from_price - b.stickerpricedata.price;
                } else {
                    return (
                        a.dynamic_from_price - b.dynamic_from_price ||
                        getCarName(a).localeCompare(getCarName(b))
                    );
                }
            });
            break;
        case OrderBy.FROM_PRICE_DESC:
            carList.sort(
                (a, b) =>
                    b.dynamic_from_price - a.dynamic_from_price ||
                    getCarName(a).localeCompare(getCarName(b)),
            );
            break;
        case OrderBy.ALPHABETICAL:
            carList.sort((a, b) => getCarName(a).localeCompare(getCarName(b)));
            break;
        case OrderBy.ALPHABETICAL_DESC:
            carList.sort((a, b) => getCarName(a).localeCompare(getCarName(b)));
            carList.reverse();
            break;
        case OrderBy.RANGE:
            carList.sort(
                (a, b) =>
                    a.technicaldata.range - b.technicaldata.range ||
                    getCarName(a).localeCompare(getCarName(b)),
            );
            break;
        case OrderBy.RANGE_DESC:
            carList.sort(
                (a, b) =>
                    b.technicaldata.range - a.technicaldata.range ||
                    getCarName(a).localeCompare(getCarName(b)),
            );
            break;
        case OrderBy.POWER_CONSUMPTION:
            carList.sort(
                (a, b) =>
                    a.technicaldata.power_consumption - b.technicaldata.power_consumption ||
                    getCarName(a).localeCompare(getCarName(b)),
            );
            break;
        case OrderBy.POWER_CONSUMPTION_DESC:
            carList.sort(
                (a, b) =>
                    b.technicaldata.power_consumption - a.technicaldata.power_consumption ||
                    getCarName(a).localeCompare(getCarName(b)),
            );
            break;
        case OrderBy.CHARGING_RATE_AC:
            carList.sort(
                (a, b) =>
                    a.technicaldata.charging_rate_ac_max - b.technicaldata.charging_rate_ac_max ||
                    getCarName(a).localeCompare(getCarName(b)),
            );
            break;
        case OrderBy.CHARGING_RATE_AC_DESC:
            carList.sort(
                (a, b) =>
                    b.technicaldata.charging_rate_ac_max - a.technicaldata.charging_rate_ac_max ||
                    getCarName(a).localeCompare(getCarName(b)),
            );
            break;
        case OrderBy.CHARGING_RATE_DC:
            carList.sort(
                (a, b) =>
                    a.technicaldata.charging_rate_dc_max - b.technicaldata.charging_rate_dc_max ||
                    getCarName(a).localeCompare(getCarName(b)),
            );
            break;
        case OrderBy.CHARGING_RATE_DC_DESC:
            carList.sort(
                (a, b) =>
                    b.technicaldata.charging_rate_dc_max - a.technicaldata.charging_rate_dc_max ||
                    getCarName(a).localeCompare(getCarName(b)),
            );
            break;
        case OrderBy.CHARGING_TIME_DC:
            carList.sort(
                (a, b) =>
                    a.technicaldata.charge_time_dc - b.technicaldata.charge_time_dc ||
                    getCarName(a).localeCompare(getCarName(b)),
            );
            break;
    }
}

/**
 * Calculate the svg's viewBox attribute by JS.
 *
 * @param svgElements nodeList with svgElements
 */
export function normalizeSvgViewBoxes(svgElements: NodeListOf<SVGGraphicsElement>) {
    for (const svg of svgElements) {
        normalizeSvgViewBox(svg);
    }
}

/**
 * Calculate the svg's viewBox attribute by JS and set it.
 *
 * @param svgElement single svg graphics element
 */
export function normalizeSvgViewBox(svgElement: SVGGraphicsElement) {
    const bbox = svgElement.getBBox();
    const viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(' ');

    svgElement.setAttribute('viewBox', viewBox);
}

/**
 * Capitalize the first letter of a string.
 *
 * @param s string to capitalize
 */
export function capitalize(s: string) {
    return s && s[0].toUpperCase() + s.slice(1);
}

/**
 * Decapitalize the first letter of a string.
 *
 * @param s string to decapitalize
 */
export function decapitalize(s: string) {
    return s && s[0].toLowerCase() + s.slice(1);
}

export const cantons: ICanton[] = [
    { name: 'Aargau', code: 'ag' },
    { name: 'Appenzell Außerrhoden', code: 'ar' },
    { name: 'Appenzell Innerrhoden', code: 'ai' },
    { name: 'Basel-Landschaft', code: 'bl' },
    { name: 'Basel-Stadt', code: 'bs' },
    { name: 'Bern', code: 'be' },
    { name: 'Freiburg', code: 'fr' },
    { name: 'Genf', code: 'ge' },
    { name: 'Glarus', code: 'gl' },
    { name: 'Graubünden', code: 'gr' },
    { name: 'Jura', code: 'ju' },
    { name: 'Luzern', code: 'lu' },
    { name: 'Neuenburg', code: 'ne' },
    { name: 'Nidwalden', code: 'nw' },
    { name: 'Obwalden', code: 'ow' },
    { name: 'Schaffhausen', code: 'sh' },
    { name: 'Schwyz', code: 'sz' },
    { name: 'Solothurn', code: 'so' },
    { name: 'St. Gallen', code: 'sg' },
    { name: 'Tessin', code: 'ti' },
    { name: 'Thurgau', code: 'tg' },
    { name: 'Uri', code: 'ur' },
    { name: 'Waadt', code: 'vd' },
    { name: 'Wallis', code: 'vs' },
    { name: 'Zürich', code: 'zh' },
    { name: 'Zug', code: 'zg' },
];

/**
 * Compare two price request objects for equality.
 *
 * @param initial
 * @param stored
 */
export function isEqualPriceReq(initial: IPriceRequest, stored: IPriceRequest): boolean {
    const equal = require('fast-deep-equal/es6');

    return equal(initial, stored);
}

/**
 * Get the gender name by Gender enum.
 *
 * @param gender
 */
export function getGenderName(gender: Gender): string {
    switch (gender) {
        case Gender.FEMALE:
            return i18n.tc('words.female');
        case Gender.MALE:
            return i18n.tc('words.male');
        case Gender.INTER:
            return i18n.tc('words.diverse');
    }
}

/**
 * Go one step back (used in navigation buttons).
 *
 * @param route
 */
export function goBack(route: Route) {
    const configuratorStore = useConfiguratorStore();

    switch (route.name) {
        case 'configure':
            router.push({ name: 'selector' });
            break;
        case 'pricedetail':
            if (configuratorStore.car) {
                router.push({
                    name: 'configure',
                    params: { slug: configuratorStore.car.url_slug },
                });
            } else {
                throw new Error('car is undefined');
            }
            break;
        case 'customerform':
            router.push({ name: 'pricedetail' });
            break;
        case 'summary':
            router.push({ name: 'customerform' });
    }
}

/**
 * Set the focus to the given HtmlInputElement.
 *
 * @param elem HTML input element
 */
export async function focusElemRef(elem: HTMLInputElement | undefined) {
    if (elem) {
        await nextTick(() => {
            elem.focus();
        });
    }
}

/**
 * Calculate the net value from given amount.
 *
 * @param amount amount
 * @param c country
 * @return net value
 */
export function getNet(amount: number, c: Country): number {
    switch (c) {
        case Country.AT:
            return Math.round(amount / 1.2);
        case Country.DE:
            return Math.round(amount / 1.19);
        case Country.CH:
            return Math.round(amount / 1.077);
        case Country.FR:
            return Math.round(amount / 1.2);
    }
}
