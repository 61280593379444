
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import vSelect from 'vue-select';
    import 'vue-select/dist/vue-select.css';
    import InfoModal from '@/components/configurator/InfoModal.vue';
    import Tooltip from '@/components/configurator/Tooltip.vue';
    import { ICarFull } from '@/interfaces/car/ICarFull';
    import { cantons } from '@/util/misc';
    import { useConfiguratorStore } from '@/store/configurator';

    @Component({
        components: {
            vSelect,
            InfoModal,
            Tooltip,
        },
    })
    export default class CantonChooser extends Vue {
        @Prop() car!: ICarFull;
        cantons = cantons;
        infoContentCanton = `
        <strong>Kanton</strong>
            <p>Hier ist jener Kanton auszuwählen, in dem das Fahrzeug üblicherweise über Nacht abgestellt wird.</p>
    `;

        get configuratorStore() {
            return useConfiguratorStore();
        }
    }
