
    import { Vue, Component } from 'vue-property-decorator';

    @Component
    export default class InfoModal extends Vue {
        active = false;

        clickOpen(event: Event) {
            this.preventEvent(event);
            this.active = true;
        }

        clickClose(event: Event) {
            this.preventEvent(event);
            this.active = false;
        }

        preventEvent(event: Event) {
            event.stopPropagation();
        }
    }
