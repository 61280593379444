import { IPriceRequest } from '@/interfaces/IPriceRequest';
import { HttpResponse, post } from '@/api/base';
import { IOptionalPricesResponse } from '@/interfaces/IOptionalPricesResponse';
import { IPriceResult } from '@/interfaces/IPriceResult';
import { ITemplatePriceData } from '@/interfaces/ITemplatePriceData';
import { ITemplatePriceDataRequest } from '@/interfaces/ITemplatePriceDataRequest';

export async function getPrice(req: IPriceRequest): Promise<HttpResponse<IPriceResult>> {
    return await post<IPriceResult>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/service/public/price/`,
        req,
    );
}

export async function getOptionalPrices(
    data: IPriceRequest,
): Promise<HttpResponse<IOptionalPricesResponse>> {
    return await post<IOptionalPricesResponse>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/service/public/price/optionals/`,
        data,
    );
}

export async function getTemplatePriceData(
    data: ITemplatePriceDataRequest,
): Promise<HttpResponse<ITemplatePriceData>> {
    return await post<ITemplatePriceData>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/service/public/price/template-calc-data/`,
        data,
    );
}
