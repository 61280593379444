export enum OrderBy {
    DELIVERY_TIME,
    FROM_PRICE,
    FROM_PRICE_DESC,
    ALPHABETICAL,
    ALPHABETICAL_DESC,
    RANGE,
    RANGE_DESC,
    POWER_CONSUMPTION,
    POWER_CONSUMPTION_DESC,
    CHARGING_RATE_DC,
    CHARGING_RATE_DC_DESC,
    CHARGING_RATE_AC,
    CHARGING_RATE_AC_DESC,
    CHARGING_TIME_DC,
    POPULARITY,
}
