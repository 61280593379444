
    import { Vue, Component } from 'vue-property-decorator';
    import { useConfiguratorStore } from '@/store/configurator';

    @Component
    export default class OrderView extends Vue {
        get configuratorStore() {
            return useConfiguratorStore();
        }

        mounted() {
            if (
                !(
                    this.configuratorStore.car &&
                    this.configuratorStore.priceRequest &&
                    this.configuratorStore.priceResult
                )
            ) {
                this.$router.push({ name: 'selector' });
            }
        }
    }
