
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import FixtureChooserModalContent from '@/components/configurator/FixtureChooserModalContent.vue';
    import CircleTag from '@/components/tags/CircleTag.vue';
    import { Country } from '@/enums/Country';
    import InfoModal from '@/components/configurator/InfoModal.vue';
    import Tooltip from '@/components/configurator/Tooltip.vue';
    import { FixtureLevel } from '@/enums/FixtureLevel';
    import FixtureUnavailableModalContent from '@/components/configurator/FixtureUnavailableModalContent.vue';
    import FixtureButton from '@/components/configurator/FixtureButton.vue';
    import { ICarFull } from '@/interfaces/car/ICarFull';
    import { useConfiguratorStore } from '@/store/configurator';

    @Component({
        components: {
            FixtureButton,
            FixtureChooserModalContent,
            FixtureUnavailableModalContent,
            CircleTag,
            InfoModal,
            Tooltip,
        },
    })
    export default class FixtureChooser extends Vue {
        @Prop() car!: ICarFull;
        fixture!: any;
        country!: any;
        isFixtureChooserModalActive = false;
        isFixtureUnavailableModalActive = false;

        get configuratorStore() {
            return useConfiguratorStore();
        }

        @Watch('configuratorStore.config.color')
        onColorChanged() {
            const color = this.configuratorStore.config.color;
            const defaultFixtureLevel = this.car.fixture_default_level;

            if (!color) {
                throw new Error('color is undefined');
            }

            // and show appropriate modal window if it is an impossible combination.
            if (this.isUnavailableColorFixtureCombination()) {
                this.isFixtureUnavailableModalActive = true;

                // set another fixture:
                if (defaultFixtureLevel && color.fixture_levels.includes(defaultFixtureLevel)) {
                    // 1) use default fixture (if it is set and a possible one)
                    this.configuratorStore.config.fixture = defaultFixtureLevel;
                } else {
                    // 2) use first from color's fixture_levels
                    this.configuratorStore.config.fixture = color.fixture_levels[0];
                }
            }
        }

        /**
         * Check for unavailable color <-> fixture combination.
         */
        isUnavailableColorFixtureCombination(): boolean {
            const configuratorStore = useConfiguratorStore();
            const color = configuratorStore.config.color;
            const fixtureLevel = configuratorStore.config.fixture;

            if (!color || !fixtureLevel) {
                throw new Error('color and/or fixturelvel is undefined');
            }

            return !color.fixture_levels.includes(fixtureLevel);
        }

        /**
         * Open the fixture chooser modal, close the other modal (could act as opener).
         */
        openFixtureChooserModal() {
            this.isFixtureChooserModalActive = true;
            this.isFixtureUnavailableModalActive = false;
        }

        beforeCreate() {
            this.fixture = FixtureLevel;
            this.country = Country;
        }
    }
