import { useGtm } from '@gtm-support/vue2-gtm';

/**
 * Enable the Google Tag Manager if user accepted.
 *
 * @param consent cookieFirst consent object
 * @see https://support.cookiefirst.com/hc/de/articles/360011568738-Javascript-API
 */
export function initGtm(consent) {
    const gtm = useGtm();

    if (consent?.advertising) {
        // enable gtm if advertising cookies are accepted
        gtm?.enable(true);
    } else {
        // advertising obviously false, so disable vue-gtm at all
        gtm?.enable(false);
    }
}

export function initPosthog(posthog, consent) {
    if (consent?.performance) {
        posthog.set_config({ disable_persistence: false });
        posthog.opt_in_capturing();
        posthog.persistence.save();
    }
}

/**
 *  Create the MuatationObserver to listen for injected cookiefirst scripts after initial script was loaded.
 *  The initial script uses document.write to inject further scripts into head, so we have to choose this approach.
 *  Because we have to be sure that window.CookieFirst is available (and it's only after all of these scripts are
 *  completely loaded) before we use it to check for enabling Google Tag Manager.
 */
export function createCookieFirstObserver(posthog) {
    return new MutationObserver((mutationRecords: MutationRecord[], observer: MutationObserver) => {
        for (const record of mutationRecords) {
            if (record.addedNodes.length) {
                if (record.addedNodes[0].nodeName.toLowerCase() === 'script') {
                    // script tag found, wait for it to be loaded
                    record.addedNodes[0].addEventListener('load', () => {
                        // maybe it's the script which makes window.CookieFirst available?
                        if ('CookieFirst' in window) {
                            // yes it is
                            // we obviously found it, disconnect the observer
                            observer.disconnect();

                            // @ts-ignore
                            // init the gtm
                            initGtm(window.CookieFirst.consent);
                            // @ts-ignore
                            initPosthog(posthog, window.CookieFirst.consent);

                            // attach the event listener to re-invoke initGtm when changing consent
                            // event.detail is the consent object
                            window.addEventListener('cf_consent', (event: Event) => {
                                // @ts-ignore
                                initGtm(event.detail);
                                // @ts-ignore
                                initPosthog(posthog, event.detail);
                            });
                        }
                    });
                }
            }
        }
    });
}

/**
 *  Init the Cookie Mgmt Stuff.
 *  Check for existing CookieFirst in window (would be a miracle), otherwise start observing head for script tags.
 */
export function initCookieMgmt(posthog) {
    if (!('CookieFirst' in window)) {
        // CookieFirst not in window, start the observer
        const observer = createCookieFirstObserver(posthog);
        observer.observe(document.head, { childList: true });
    } else {
        // CookieFirst object already available, just init the Google Tag Manager and attach to 'cf_consent'
        // this is nearly impossible, but to be sure...
        // init the gtm
        // @ts-ignore
        initGtm(window.CookieFirst.consent);
        // @ts-ignore
        initPosthog(posthog, window.CookieFirst.consent);

        // attach gtm init to cf_consent event handler to re-init gtm when changing consent
        // event.detail is the consent object with new values
        window.addEventListener('cf_consent', (event: Event) => {
            // @ts-ignore
            initGtm(event.detail);
            // @ts-ignore
            initPosthog(posthog, event.detail);
        });
    }
}
