import posthog from 'posthog-js';

export default {
    install(Vue, options) {
        Vue.prototype.$posthog = posthog.init(process.env.VUE_APP_POSTHOG_PROJECT_API_KEY, {
            api_host: 'https://eu.posthog.com',
            persistence: 'cookie',
            disable_persistence: true,
            session_recording: {
                maskAllInputs: false,
                maskInputOptions: {
                    password: true, // Highly recommended as a minimum!!
                    color: false,
                    date: false,
                    'datetime-local': false,
                    email: false,
                    month: false,
                    number: false,
                    range: false,
                    search: false,
                    tel: true,
                    text: true,
                    time: false,
                    url: false,
                    week: false,
                    textarea: false,
                    select: false,
                },
            },
        });
    },
};
