import { ExtraOption } from '@/enums/ExtraOption';

export enum ConfigurationOption {
    COLOR = 'color',
    FIXTURE_LEVEL = 'fixture-level',
    TERM = 'term',
    KILOMETRAGE = 'kilometrage',
    MILEAGE = 'mileage',
    INITIAL_PAYMENT = 'initial-payment',
    ADDITIONAL_FIXTURES = 'additional-fixtures',
    CHARGING_EQUIPMENT = 'charging-equipment',
    TIRE_PACKAGE = 'tire-package',
    DELIVERY = 'delivery',
    REMOVE_BRANDING = 'remove-branding',
    BISECT_RETENTION = 'bisect-retention',
    MOBILITY_WARRANTY = 'mobility-warranty',
}

/**
 * Convert an extra option to instalytics' configurator option
 *
 * @param eo extra option
 * @return configurator option
 */
export function convertOption(eo: ExtraOption): ConfigurationOption {
    switch (eo) {
        case ExtraOption.REMOVE_BRANDING:
            return ConfigurationOption.REMOVE_BRANDING;
        case ExtraOption.BISECT_RETENTION:
            return ConfigurationOption.BISECT_RETENTION;
        case ExtraOption.WARRANTY_ASSISTANCE:
            return ConfigurationOption.MOBILITY_WARRANTY;
        default:
            throw new Error('Unknown extra option');
    }
}
