import { FixtureLevel } from '@/enums/FixtureLevel';
import { IConfiguration } from '@/interfaces/IConfiguration';
import { IPriceRequest } from '@/interfaces/IPriceRequest';
import { IColor } from '@/interfaces/car/IColor';
import { ICar } from '@/interfaces/car/ICar';
import { ICarFull } from '@/interfaces/car/ICarFull';
import { IStandardChargingEquipmentItem } from '@/interfaces/IStandardChargingEquipmentItem';
import { IFixtureSavedDesc } from '@/interfaces/order/IFixtureSavedDesc';
import { Country } from '@/enums/Country';
import i18n from '@/i18n';
import { IMake } from '@/interfaces/car/IMake';
import { IInitialPaymentAmount } from '@/interfaces/IInitialPaymentAmount';

export function getName(car: ICar | undefined): string {
    if (!car) {
        return '';
    }

    return `${(car.model_variant.model.make as IMake).name} ${car.model_variant.model.name} ${
        car.model_variant.name || ''
    }`;
}

export function getImageURL(image: string): string {
    return `${process.env.VUE_APP_IC_SERVICE_HOST}${image}`;
}

/**
 * Get the default color.
 *
 * @param car car object
 * @param considerStickerPrice whether to consider the stickerpricedata or not
 */
export function getDefaultColor(car: ICarFull, considerStickerPrice = false): IColor | undefined {
    if (considerStickerPrice && car.stickerpricedata) {
        return car.stickerpricedata.color;
    }

    const dc = car.colors.find((c) => c.id === car.default_color_id);

    return !dc ? undefined : dc;
}

export function getColor(id: number, car: ICarFull): IColor | undefined {
    const dc = car.colors.find((c) => c.id === id);

    return !dc ? undefined : dc;
}

export function getMakeIcon(car: ICar | undefined) {
    if (!car) {
        return null;
    }

    if (car.model_variant.model.make.id === 5) {
        return require('!html-loader!@/assets/img/brand-icons/vw.svg');
    }

    return require(`!html-loader!@/assets/img/brand-icons/${car.model_variant.model.make.name.toLowerCase()}.svg`);
}

/**
 * Build a price request from car + configuration.
 *
 * @param car car object
 * @param config configuration object
 * @param country
 * @param businessMode
 * @param currentPriceReq current request to use as base (regarding additionals).
 */
export function buildPriceReq(
    car: ICarFull,
    config: IConfiguration,
    country: Country,
    businessMode: boolean,
    currentPriceReq: IPriceRequest | undefined = undefined,
): IPriceRequest {
    if (!country) {
        throw new Error('country is undefined');
    }

    const res: IPriceRequest = {
        carId: car.id,
        colorId: config.color?.id,
        countryCode: country,
        businessMode: businessMode,
        fixtureLevel: config.fixture,
        kilometerPerYear: config.kilometerPerYear,
        termInMonths: config.term,
        initialPaymentAmount: config.initialPaymentAmount?.amount,
        canton: config.canton?.code,
        facilitationSpecials: config.facilitationSpecials,
        includeDetails: true,
        tirePackage: false,
        delivery: null,
        extraOptions: [],
        additionalFixtures: [],
        chargingEquipment: [],
    };

    if (config.initialPaymentAmount?.amount == 0) {
        // remove € 0 ipa from array
        const ipas = [...car.initial_payment_amounts].filter((item) => item.amount != 0);

        // set minimum ipa as deposit
        res.depositAmount = Math.min(...ipas.map((item) => item.amount));
    }

    if (currentPriceReq !== undefined) {
        res.tirePackage = currentPriceReq.tirePackage;
        res.delivery = currentPriceReq.delivery;
        res.extraOptions = currentPriceReq.extraOptions;
        res.additionalFixtures = currentPriceReq.additionalFixtures;
        res.chargingEquipment = currentPriceReq.chargingEquipment;
    }

    return res;
}

export function getSCE(f: FixtureLevel, car: ICarFull): IStandardChargingEquipmentItem[] {
    switch (f) {
        case FixtureLevel.LEVEL1:
            return car.standardchargingequipment.filter((sce) =>
                sce.fixture_levels.includes(FixtureLevel.LEVEL1),
            );
        case FixtureLevel.LEVEL2:
            return car.standardchargingequipment.filter((sce) =>
                sce.fixture_levels.includes(FixtureLevel.LEVEL2),
            );
        case FixtureLevel.LEVEL3:
            return car.standardchargingequipment.filter((sce) =>
                sce.fixture_levels.includes(FixtureLevel.LEVEL3),
            );
    }
}

/**
 * Get level name from fixture level.
 *
 * @param f
 */
export function getLevelName(f: FixtureLevel) {
    switch (f) {
        case FixtureLevel.LEVEL1:
            return i18n.tc('words.fixture_level1');
        case FixtureLevel.LEVEL2:
            return i18n.tc('words.fixture_level2');
        case FixtureLevel.LEVEL3:
            return i18n.tc('words.fixture_level3');
    }
}

/**
 * Get the saved fixture description object by car and color.
 *
 * @param car car object
 */
export function getFixtureDesc(car: ICarFull): IFixtureSavedDesc {
    const res: IFixtureSavedDesc = {};

    if (car.fixturedetails.first_level_items) {
        res.level1 = car.fixturedetails.first_level_items;
    }

    if (car.fixturedetails.second_level_items) {
        res.level2 = car.fixturedetails.second_level_items;
    }

    if (car.fixturedetails.third_level_items) {
        res.level3 = car.fixturedetails.third_level_items;
    }

    return res;
}

/**
 * Check for unavailable fixture levels.
 *
 * @param f the fixture level
 * @param car car object.
 */
export function isUnavailableFixture(f: FixtureLevel, car: ICarFull) {
    switch (f) {
        case FixtureLevel.LEVEL1:
            return car.fixturedetails.first_level_is_unavailable;
        case FixtureLevel.LEVEL2:
            return car.fixturedetails.second_level_is_unavailable;
        case FixtureLevel.LEVEL3:
            return car.fixturedetails.third_level_is_unavailable;
    }
}

/**
 * Get the default mileage/kilometer per year.
 *
 * @param car car object
 */
export function getDefaultMileage(car: ICar): number {
    if (car.stickerpricedata) {
        return car.stickerpricedata.mileage;
    }

    return 5;
}

/**
 * Get the default 'term in months'.
 *
 * @param car car object
 */
export function getDefaultTerm(car: ICar) {
    if (car.stickerpricedata) {
        return car.stickerpricedata.term;
    }

    return 24;
}

/**
 * Get the default initial payment amount.
 *
 * @param car car object
 */
export function getDefaultIPA(car: ICarFull): IInitialPaymentAmount {
    if (car.stickerpricedata) {
        const ipa = car.initial_payment_amounts.find(
            (item) => item.amount == car.stickerpricedata.initial_payment_amount,
        );

        if (ipa) {
            return ipa;
        }
    }

    return car.initial_payment_amount_default;
}

/**
 * Get the default fixture level.
 *
 * @param car car object
 */
export function getDefaultFixtureLevel(car: ICarFull): FixtureLevel {
    if (car.stickerpricedata) {
        return car.stickerpricedata.fixture_level;
    }

    return car.fixture_default_level;
}
