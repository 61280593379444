
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import ColorChooser from '@/components/configurator/ColorChooser.vue';
    import TermChooser from '@/components/configurator/TermChooser.vue';
    import KmChooser from '@/components/configurator/KmChooser.vue';
    import IPAChooser from '@/components/configurator/IPAChooser.vue';
    import ResultBox from '@/components/configurator/ResultBox.vue';
    import FixtureChooser from '@/components/configurator/FixtureChooser.vue';
    import {
        buildPriceReq,
        getDefaultColor,
        getDefaultMileage,
        getDefaultTerm,
        getDefaultIPA,
        getDefaultFixtureLevel,
    } from '@/util/car';
    import { Country } from '@/enums/Country';
    import CantonChooser from '@/components/configurator/CantonChooser.vue';
    import Additionals from '@/components/configurator/Additionals.vue';
    import { useGlobalStore } from '@/store/global';
    import { useConfiguratorStore } from '@/store/configurator';
    import { useCustomerStore } from '@/store/customer';
    import CarImage from '@/components/CarImage.vue';
    import CarTag from '@/components/tags/CarTag.vue';
    import { VehicleState } from '@/enums/VehicleState';

    @Component({
        components: {
            Additionals,
            CantonChooser,
            CarImage,
            FixtureChooser,
            ResultBox,
            IPAChooser,
            KmChooser,
            TermChooser,
            ColorChooser,
            CarTag,
        },
    })
    export default class Configurator extends Vue {
        country: any;
        vehicleState = VehicleState;

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        async onContinue() {
            // wait for the route to be pushed
            await this.$router.push({ name: 'pricedetail' });
        }

        // when trial abo (6 months) is selected, choose 0 ipa
        @Watch('configuratorStore.config.term')
        onChangingTerms(value, prevValue) {
            if (value === 6) {
                if (
                    this.configuratorStore.car &&
                    this.configuratorStore.car.initial_payment_amounts &&
                    this.configuratorStore.car.initial_payment_amounts.some(
                        (ipa) => ipa.amount == 0,
                    )
                ) {
                    this.configuratorStore.config.initialPaymentAmount =
                        this.configuratorStore.car.initial_payment_amounts.find(
                            (ipa) => ipa.amount == 0,
                        );
                } else {
                    this.configuratorStore.config.initialPaymentAmount = { amount: 0 };
                }
            } else if (
                this.configuratorStore.car &&
                prevValue === 6 &&
                this.configuratorStore.config.initialPaymentAmount?.amount == 0
            ) {
                this.configuratorStore.config.initialPaymentAmount =
                    this.configuratorStore.car.initial_payment_amount_default;
            }
        }

        async mounted() {
            this.configuratorStore.ignoreChangesInstalytics = true;
            await this.configuratorStore.retrieveCar();

            if (!this.configuratorStore.car) {
                throw new Error('car is undefined');
            }

            if (!this.globalStore.country) {
                throw new Error('country is undefined');
            }

            // choose the default swiss canton
            if (this.globalStore.country === Country.CH) {
                if (!this.configuratorStore.config.canton) {
                    this.configuratorStore.config.canton = { name: 'Zürich', code: 'zh' };
                }
            }

            // reset all choices to standard when coming from selector
            if (this.globalStore.lastURL === 'selector' || this.globalStore.lastURL === '') {
                this.configuratorStore.config.kilometerPerYear = getDefaultMileage(
                    this.configuratorStore.car,
                );
                this.configuratorStore.config.term = getDefaultTerm(this.configuratorStore.car);
                this.configuratorStore.config.initialPaymentAmount = getDefaultIPA(
                    this.configuratorStore.car,
                );
                this.configuratorStore.config.color = getDefaultColor(
                    this.configuratorStore.car,
                    true,
                );
                this.configuratorStore.config.fixture = getDefaultFixtureLevel(
                    this.configuratorStore.car,
                );

                // build price request
                this.configuratorStore.priceRequest = buildPriceReq(
                    this.configuratorStore.car,
                    this.configuratorStore.config,
                    this.globalStore.country,
                    Boolean(this.customerStore.customer.is_company),
                );

                // retrieve data for representing price result
                await this.configuratorStore.retrieveOptionalPrices();

                this.configuratorStore.priceRequest = buildPriceReq(
                    this.configuratorStore.car,
                    this.configuratorStore.config,
                    this.globalStore.country,
                    Boolean(this.customerStore.customer.is_company),
                );
                await this.configuratorStore.retrievePrice();
                await this.configuratorStore.retrieveChargingEquip();
            } else {
                // Else check if all important values are set, take default values when necessary
                if (!this.configuratorStore.config.initialPaymentAmount) {
                    this.configuratorStore.config.initialPaymentAmount = getDefaultIPA(
                        this.configuratorStore.car,
                    );
                }

                if (
                    !this.configuratorStore.config.color ||
                    this.configuratorStore.config.color.car !== this.configuratorStore.car.id
                ) {
                    // if color is undefined or not linked to current car, use default color
                    this.configuratorStore.config.color = getDefaultColor(
                        this.configuratorStore.car,
                        true,
                    );
                }

                if (!this.configuratorStore.config.fixture) {
                    // if fixture is undefined or unavailable use default fixture level
                    this.configuratorStore.config.fixture = getDefaultFixtureLevel(
                        this.configuratorStore.car,
                    );
                }
            }

            this.configuratorStore.ignoreChangesInstalytics = false;
        }

        beforeCreate() {
            this.country = Country;
            this.vehicleState = VehicleState;
        }
    }
