
    import { Component, Vue } from 'vue-property-decorator';
    import { GlobalStep } from '@/enums/GlobalStep';
    import { useGlobalStore } from '@/store/global';
    import { useSemiLoginStore } from '@/store/semilogin';

    @Component
    export default class ConfiguratorView extends Vue {
        get semiLoginStore() {
            return useSemiLoginStore();
        }

        async mounted() {
            // @ts-ignore
            // this.$posthog.startSessionRecording();
            const globalStore = useGlobalStore();
            globalStore.currentStep = GlobalStep.CONFIGURATION;
        }
    }
