
    import { GlobalStep } from '@/enums/GlobalStep';
    // @ts-ignore
    import LottieAnimation from 'lottie-web-vue';
    import { Component, Vue } from 'vue-property-decorator';
    import { useGlobalStore } from '@/store/global';

    @Component({
        components: {
            LottieAnimation,
        },
    })
    export default class Congratulations extends Vue {
        get globalStore() {
            return useGlobalStore();
        }

        get website() {
            return process.env.VUE_APP_WEBSITE_DOMAIN;
        }

        mounted() {
            this.globalStore.currentStep = GlobalStep.CONGRATULATIONS;
        }
    }
