
    import { Component, Vue } from 'vue-property-decorator';
    import { useGlobalStore } from '@/store/global';

    @Component
    export default class NotFound extends Vue {
        get selectorLink() {
            return this.$router.resolve({ name: 'selector' }).href;
        }

        get homepageLink() {
            const globalStore = useGlobalStore();

            return `//${process.env.VUE_APP_WEBSITE_DOMAIN}/${globalStore.country}/`;
        }
    }
