import { HttpResponse, post } from '@/api/base';
import { IOrder, IOrderResult } from '@/interfaces/order/IOrder';

/**
 * Add an order confirmation to IC Offerstore.
 *
 * @param order order data
 */
export async function addOrder(order: IOrder): Promise<HttpResponse<IOrderResult>> {
    return await post<IOrderResult>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/offerstore/order/`,
        order,
    );
}
