import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import { localeChanged } from 'vee-validate';

Vue.use(VueI18n);

/**
 * Load translation content from json files.
 */
function loadLocaleMessages(): LocaleMessages {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: LocaleMessages = {};

    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);

        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });

    return messages;
}

const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages(),
});

/**
 * Set the current language.
 *
 * @param cc country code
 */
export function setI18nLanguage(cc: string) {
    if (cc === 'at' || cc === 'de' || cc === 'ch') {
        i18n.locale = 'de';
    } else if (cc === 'it') {
        i18n.locale = 'it';
    } else if (cc === 'fr') {
        i18n.locale = 'fr';
    } else if (cc === 'eng') {
        i18n.locale = 'en';
    } else {
        throw new Error(`invalid country code given: ${cc}, cannot set i18n language`);
    }

    localeChanged(); // notify vee-validate of localization changes

    document.querySelector('html')?.setAttribute('lang', i18n.locale);
    document.title = i18n.t('app_title').toString();
}

export default i18n;
