var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"tag",class:{
        new: _vm.tagtype === 'new',
        ia: _vm.tagtype === 'ia',
        ua: _vm.tagtype === 'ua',
        bd: _vm.tagtype === 'bd',
        qa: _vm.tagtype === 'qa',
        iu: _vm.tagtype === 'iu',
        jw: _vm.tagtype === 'jw',
    }},[(_vm.tagtype === 'new')?[_c('span',[_vm._v(_vm._s(_vm.$tc('words.new_cars')))])]:_vm._e(),(_vm.tagtype === 'bd')?[_c('span',[_vm._v("% "+_vm._s(_vm.$tc('tags.top'))+" %")])]:_vm._e(),(_vm.tagtype === 'ia')?_c('span',{staticClass:"flash",attrs:{"title":"Flash Icon"},domProps:{"innerHTML":_vm._s(require('!html-loader!@/assets/img/ios-flash.svg'))}}):_vm._e(),(_vm.tagtype === 'ia')?[_c('span',[_vm._v(_vm._s(_vm.$tc('tags.ia')))])]:_vm._e(),(_vm.tagtype === 'qa')?_c('span',{staticClass:"tag-icon",attrs:{"title":"Fast Forward Icon"},domProps:{"innerHTML":_vm._s(require('!html-loader!@/assets/img/fast-forward.svg'))}}):_vm._e(),(_vm.tagtype === 'qa')?[_c('span',[_vm._v(_vm._s(_vm.$tc('tags.qa')))])]:_vm._e(),(_vm.tagtype === 'iu')?[_c('span',[_vm._v(_vm._s(_vm.$tc('tags.iu')))])]:_vm._e(),(_vm.tagtype === 'jw')?[_c('span',[_vm._v(_vm._s(_vm.$tc('tags.jw')))])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }