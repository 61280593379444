
    import { Component, Vue } from 'vue-property-decorator';
    import { IChargingEquipment } from '@/interfaces/order/IChargingEquipment';
    import { normalizeSvgViewBoxes } from '@/util/misc';
    import { useConfiguratorStore } from '@/store/configurator';
    import { useGlobalStore } from '@/store/global';
    import { useCustomerStore } from '@/store/customer';
    import { ConfigurationOption } from '@/enums/ConfigurationOption';

    @Component
    export default class ChargingEquipment extends Vue {
        itemsOpen = false;

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        hasEquip(id: number) {
            return this.configuratorStore.priceRequest?.chargingEquipment?.find(
                (item) => item.id === id,
            );
        }

        /**
         * Click handler for adding/removing charging equipment item.
         *
         * @param equipItem
         */
        async onEquipClick(equipItem: IChargingEquipment) {
            if (!this.configuratorStore.priceRequest) {
                throw new Error('invalid price request');
            }

            if (this.configuratorStore.priceRequest.chargingEquipment === undefined) {
                throw new Error('charging equipment is undefined');
            }

            if (this.hasEquip(equipItem.id)) {
                for (const [
                    i,
                    item,
                ] of this.configuratorStore.priceRequest.chargingEquipment.entries()) {
                    if (item.id === equipItem.id) {
                        // save the current additionalFixtures list for instalytics
                        const oldValue = [...this.configuratorStore.priceRequest.chargingEquipment];

                        this.configuratorStore.priceRequest.chargingEquipment?.splice(i, 1);
                        this.configuratorStore.sendToInstalytics(
                            ConfigurationOption.CHARGING_EQUIPMENT,
                            oldValue,
                            this.configuratorStore.priceRequest.chargingEquipment,
                        );
                    }
                }
            } else {
                // save the current chargingEquipment list for instalytics
                const oldValue = [...this.configuratorStore.priceRequest.chargingEquipment];

                this.configuratorStore.priceRequest.chargingEquipment?.push(equipItem);
                this.configuratorStore.sendToInstalytics(
                    ConfigurationOption.CHARGING_EQUIPMENT,
                    oldValue,
                    this.configuratorStore.priceRequest.chargingEquipment,
                );
            }

            await this.configuratorStore.retrievePrice();
        }

        mounted() {
            this.$nextTick(function () {
                // Code that will run only after the entire view has been rendered
                normalizeSvgViewBoxes(this.$el.querySelectorAll('span.svg > svg'));
            });
        }
    }
