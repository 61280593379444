
    import { Component, Vue } from 'vue-property-decorator';
    import { goBack } from '@/util/misc';
    import i18n from '@/i18n';

    @Component
    export default class NaviButton extends Vue {
        get buttonText() {
            if (this.$route.name === 'configure') {
                return i18n.tc('buttons.back_selection');
            }

            if (this.$route.name === 'pricedetail') {
                return i18n.tc('buttons.back_configuration');
            }

            if (this.$route.name === 'customerform') {
                return i18n.tc('buttons.back_price_detail');
            }

            return i18n.tc('buttons.back_contract_data');
        }

        goBack() {
            goBack(this.$route);
        }
    }
