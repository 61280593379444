
    import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
    import CircleTag from '@/components/tags/CircleTag.vue';
    import { FixtureLevel } from '@/enums/FixtureLevel';
    import { getLevelName, isUnavailableFixture } from '@/util/car';
    import { ICarFull } from '@/interfaces/car/ICarFull';
    import { useConfiguratorStore } from '@/store/configurator';
    import { useGlobalStore } from '@/store/global';
    import { isDeliveryTimeInInterval } from '@/util/misc';

    @Component({
        components: {
            CircleTag,
        },
    })
    export default class FixtureButton extends Vue {
        @Prop() car!: ICarFull;
        @Prop() fixtureLevel!: FixtureLevel;
        fixture!: any;

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        @Emit('fixtureChanged')
        onButtonClicked(fl: FixtureLevel) {
            this.configuratorStore.config.fixture = fl;
        }

        getLevelName(f: FixtureLevel) {
            return getLevelName(f);
        }

        isIA(f: FixtureLevel) {
            // @TODO: don't know why this happens when opening FixtureUnavailableModalContent.. figure that out
            if (!this.car) {
                return false;
            }

            if (!this.configuratorStore.config.color) {
                throw new Error('color is undefined');
            }

            return isDeliveryTimeInInterval(
                this.configuratorStore.config.color,
                f,
                this.car,
                this.globalStore.iaInterval,
            );
        }

        /**
         * Check for generally unavailable fixture package.
         *
         * @param f fixture level
         */
        isGenerallyUnavailable(f: FixtureLevel) {
            // @TODO: don't know why this happens when opening FixtureUnavailableModalContent.. figure that out
            if (!this.car) {
                return false;
            }

            return isUnavailableFixture(f, this.car);
        }

        /**
         * Check for unavailable fixture <-> color combination.
         *
         * @param f fixture level
         */
        isAvailableWithColor(f: FixtureLevel) {
            if (!this.configuratorStore.config.color) {
                throw new Error('configColor is undefined');
            }

            return (
                this.configuratorStore.config.color.fixture_levels &&
                this.configuratorStore.config.color.fixture_levels.includes(f)
            );
        }

        /**
         * Wrapper to check availability in template.
         *
         * @param f fixture level
         */
        isUnavailable(f: FixtureLevel) {
            return this.isGenerallyUnavailable(f) || !this.isAvailableWithColor(f);
        }

        beforeCreate() {
            this.fixture = FixtureLevel;
        }
    }
