
    import { ValidationProvider } from 'vee-validate';
    import { Component, Vue } from 'vue-property-decorator';
    import { getColor, getDefaultColor, getLevelName } from '@/util/car';
    import PriceTable from '@/components/PriceTable.vue';
    import { useConfiguratorStore } from '@/store/configurator';
    import { useCustomerStore } from '@/store/customer';
    import { getTemplatePriceData } from '@/api/price';
    import { ITemplatePriceData } from '@/interfaces/ITemplatePriceData';
    import { FixtureLevel } from '@/enums/FixtureLevel';
    import { useGlobalStore } from '@/store/global';
    import { GlobalStep } from '@/enums/GlobalStep';
    import { Country } from '@/enums/Country';
    import { Gender } from '@/enums/Gender';
    import { getGenderName } from '@/util/misc';
    import { useOrderStore } from '@/store/order';
    import { ExtraOption } from '@/enums/ExtraOption';
    import i18n from '@/i18n';
    import { addEventInteraction } from '@/api/instalytics';
    import { IMake } from '@/interfaces/car/IMake';
    import { EventType } from '@/interfaces/instalytics/interaction';
    import { getURLFromWebsite } from '@/util/urls';
    import WithdrawalBadge from '../WithdrawalBadge.vue';
    import BenefitsPopup from '@/components/BenefitsPopup.vue';
    import BottomPriceBar from '@/components/BottomPriceBar.vue';

    @Component({
        components: {
            PriceTable,
            WithdrawalBadge,
            ValidationProvider,
            BenefitsPopup,
            BottomPriceBar,
        },
    })
    export default class Summary extends Vue {
        country: any;
        templateCalcData: ITemplatePriceData | null = null;
        hasAcceptedWithdrawal = false;
        hasAcceptedAGB = false;
        orderNowButtonIsLoading = false;

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        get orderStore() {
            return useOrderStore();
        }

        get disableOrderButton(): boolean {
            if (
                this.configuratorStore.priceResult?.dt.days !== undefined &&
                this.configuratorStore.priceResult?.dt.days <= 14
            ) {
                return !(this.hasAcceptedWithdrawal && this.hasAcceptedAGB);
            } else {
                return !this.hasAcceptedAGB;
            }
        }

        get color() {
            if (!this.configuratorStore.priceRequest) {
                throw new Error('invalid price request');
            }

            if (!this.configuratorStore.car) {
                throw new Error('car is undefined');
            }

            if (!this.configuratorStore.priceRequest.colorId) {
                return getDefaultColor(this.configuratorStore.car);
            }

            return getColor(
                this.configuratorStore.priceRequest.colorId,
                this.configuratorStore.car,
            );
        }

        getURLFromWebsite(path: string) {
            return getURLFromWebsite(path);
        }

        getExtraOptionText(eo: ExtraOption) {
            switch (eo) {
                case ExtraOption.WARRANTY_ASSISTANCE:
                    return i18n.tc('words.mobility_warranty');
                case ExtraOption.BISECT_RETENTION:
                    return i18n.tc('messages.draft.half_of_retention');
                case ExtraOption.REMOVE_BRANDING:
                    return i18n.tc('messages.draft.remove_branding');
            }
        }

        getLevelName(fl: FixtureLevel) {
            return getLevelName(fl);
        }

        getGenderName(gender: Gender) {
            return getGenderName(gender);
        }

        goToConfiguration() {
            if (!this.configuratorStore.car) {
                throw new Error('car is undefined');
            }

            this.$router.push({
                name: 'configure',
                params: { slug: this.configuratorStore.car.url_slug },
            });
        }

        goToPersonalData() {
            this.$router.push({ name: 'customerform' });
        }

        async dispatch() {
            this.orderNowButtonIsLoading = true;

            // add or update customer (await it: dispatchOrder could need it)
            await this.customerStore.dispatchCustomerAddOrUpdate();
            await this.orderStore.dispatchOrder();
            Vue.prototype.$posthog.capture('User hat erfolgreich bestellt');

            this.orderNowButtonIsLoading = false;
            this.$router.push('congratulations');
        }

        async mounted() {
            if (!this.configuratorStore.car || !this.globalStore.country) {
                throw new Error('car or country undefined!');
            }

            this.globalStore.currentStep = GlobalStep.SUMMARY;

            addEventInteraction({
                email: this.customerStore.customer.mail_address,
                country: this.globalStore.country,
                make: {
                    id: (this.configuratorStore.car.model_variant.model.make as IMake).id,
                    name: (this.configuratorStore.car.model_variant.model.make as IMake).name,
                },
                car: {
                    id: this.configuratorStore.car.id,
                    name: `${this.configuratorStore.car.model_variant.model.name} ${
                        this.configuratorStore.car.model_variant.name || ''
                    }`,
                    model_year: this.configuratorStore.car.model_year,
                    vehicle_state: this.configuratorStore.car.vehicle_state,
                },
                type: EventType.REACHED_SUMMARY,
            });

            if (!this.configuratorStore.priceRequest || !this.configuratorStore.priceResult) {
                throw new Error('PriceRequest or Result undefined');
            }

            const templateDataResp = await getTemplatePriceData({
                price_result: this.configuratorStore.priceResult,
                price_request: this.configuratorStore.priceRequest,
            });

            if (!templateDataResp.parsedBody) {
                throw new Error('Cannot retrieve template calc data');
            }

            this.templateCalcData = templateDataResp.parsedBody;
        }

        beforeCreate() {
            this.country = Country;
        }
    }
