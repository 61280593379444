import { HttpResponse, post } from '@/api/base';
import {
    ITrialRequestResult,
    ITrialSubscriptionRequest,
} from '@/interfaces/ITrialSubscriptionRequest';

/**
 * Add a trial request subscription to IC Offerstore.
 *
 * @param trial trial data
 */
export async function addTrial(
    trial: ITrialSubscriptionRequest,
): Promise<HttpResponse<ITrialRequestResult>> {
    return await post<ITrialRequestResult>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/offerstore/trial/`,
        trial,
    );
}
