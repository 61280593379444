
    import { Component, Vue } from 'vue-property-decorator';
    import { CompanyFleetSize, getCompanyFleetSizeText } from '@/enums/CompanyFleetSize';
    import { ValidationProvider } from 'vee-validate';
    import { useCustomerStore } from '@/store/customer';

    @Component({
        components: {
            ValidationProvider,
        },
    })
    export default class IsCompanyInput extends Vue {
        fleetSizeOptions = [
            CompanyFleetSize.SIZE1,
            CompanyFleetSize.SIZE2,
            CompanyFleetSize.SIZE3,
            CompanyFleetSize.SIZE4,
        ];

        get customerStore() {
            return useCustomerStore();
        }

        getFleetSizeOptionName(fleetSize: CompanyFleetSize): string {
            const sizeMessage = getCompanyFleetSizeText(fleetSize);
            return this.$tc('messages.draft.fleet_size.' + sizeMessage);
        }
    }
