import * as Sentry from '@sentry/vue';

export interface HttpResponse<T> extends Response {
    parsedBody?: T;
}

async function http<T>(request: RequestInfo, parseResponseBody = true): Promise<HttpResponse<T>> {
    const response: HttpResponse<T> = await fetch(request);

    if (parseResponseBody) {
        try {
            // may error if there is no body
            response.parsedBody = await response.json();
        } catch (ex) {
            // send excepton to sentry
            Sentry.captureException(ex);
        }
    }

    if (!response.ok && response.status !== 404) {
        throw new Error(response.statusText);
    }

    return response;
}

export async function get<T>(path: string): Promise<HttpResponse<T>> {
    const requestHeaders: HeadersInit = new Headers();

    const args: RequestInit = {
        method: 'GET',
        headers: requestHeaders,
    };

    return await http<T>(new Request(path, args));
}

export async function post<T>(
    path: string,
    body: any,
    parseResponseBody = true,
): Promise<HttpResponse<T>> {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');

    const args: RequestInit = {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify(body),
    };

    return await http<T>(new Request(path, args), parseResponseBody);
}
