export enum SelectorQueryParam {
    COUNTRY_CODE = 'cc', // set/override the chosen country
    QUIZ_RESULT = 'qr', // quiz result mode (to set: this is a quiz result)
    COOPS = 'co', // set possible cooperation overrides
    CAR_FILTER = 'cars', // display only cars with given ids
    MAKE_FILTER = 'make', // display only cars with given make ids
    VEHICLE_CLASS_FILTER = 'class',
    VEHICLE_TYPE_FILTER = 'type',
    SEAT_FILTER = 'seats',
    RANGE_FILTER = 'range',
    CHARGE_TIME_FILTER = 'chargetime',
    CHARGE_RATE_FILTER = 'chargerate',
    ACCELERATION_FILTER = 'acceleration',
    DRIVE_FILTER = 'drive',
    HITCH_FILTER = 'hitch',
    AVAILABILITY_FILTER = 'availability',
    NEW_FILTER = 'new',
    USED_FILTER = 'used',
    USED_YOUNG_FILTER = 'young',
    B2B_FILTER = 'business',
    TRIAL_SUBSCRIPTION = 'trial',
    BEST_DEAL = 'bestdeal',
    PRICE = 'price',
}
