
    import { Component, Vue } from 'vue-property-decorator';
    import { KmPerYear } from '@/enums/KmPerYear';
    import Tooltip from '@/components/configurator/Tooltip.vue';
    import InfoModal from '@/components/configurator/InfoModal.vue';
    import { buildDistancesText } from '@/util/misc';
    import i18n from '@/i18n';
    import { Country } from '@/enums/Country';
    import { MiPerYear } from '@/enums/MiPerYear';
    import { useGlobalStore } from '@/store/global';
    import { useConfiguratorStore } from '@/store/configurator';

    @Component({
        components: {
            InfoModal,
            Tooltip,
        },
    })
    export default class KmChooser extends Vue {
        showModalInfo = false;
        deInfoContent = `
        <strong>${i18n.tc('messages.config.estimate_mileages')}</strong>
            <div>
                - ${i18n.tc('messages.config.mileages.few')}<br>
                - ${i18n.tc('messages.config.mileages.second')}<br>
                - ${i18n.tc('messages.config.mileages.average')}<br>
                - ${i18n.tc('messages.config.mileages.frequent')}<br>
                <p style="margin-top: 0.7rem;">${i18n.tc('messages.config.higher_mileages')}</p>
            </div>
    `;
        country: any;

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get infoContent() {
            return this.deInfoContent;
        }

        /**
         * Create list of KmPerYear items for displaying select box (based on KmPerYear enum!)
         */
        getKms() {
            const keys = Object.keys(KmPerYear).filter(
                (k) => typeof KmPerYear[k as any] === 'number',
            );
            const values = keys.map((k) => KmPerYear[k as any]);
            const options: number[] = [];

            for (const v of values) {
                options.push(parseInt(v, 10));
            }
            return options;
        }

        /**
         * Create list of MiPerYear items for displaying select box (based on MiPerYear enum!)
         */
        getMis() {
            const keys = Object.keys(MiPerYear).filter(
                (k) => typeof MiPerYear[k as any] === 'number',
            );
            const values = keys.map((k) => MiPerYear[k as any]);
            const options: number[] = [];

            for (const v of values) {
                options.push(parseInt(v, 10));
            }

            return options;
        }

        beforeCreate() {
            this.country = Country;
        }

        getDistancesText(distance: number) {
            return buildDistancesText(distance);
        }

        closeModal() {
            this.showModalInfo = false;
        }
    }
