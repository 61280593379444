
    import { Component, Vue } from 'vue-property-decorator';
    import { getURLFromWebsite } from '@/util/urls';

    @Component
    export default class Footer extends Vue {
        getURLFromWebsite(path: string) {
            return getURLFromWebsite(path);
        }
    }
