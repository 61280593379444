import { defineStore } from 'pinia';
import { useConfiguratorStore } from '@/store/configurator';
import { useCustomerStore } from '@/store/customer';
import { IOffer, IOfferResult } from '@/interfaces/IOffer';
import { getFixtureDesc } from '@/util/car';
import { addOffer } from '@/api/offer';
import { ModalProgrammatic as Modal } from 'buefy';
import OfferInfoModal from '@/components/OfferInfoModal.vue';

export const useOfferStore = defineStore('offer', () => {
    const configuratorStore = useConfiguratorStore();
    const customerStore = useCustomerStore();

    async function dispatchOffer(): Promise<IOfferResult> {
        if (!configuratorStore.priceRequest || !configuratorStore.car) {
            throw new Error('Cannot dispatch order, invalid price request.');
        }

        const offerObj: IOffer = {
            customer_email: customerStore.customer.mail_address,
            price_request: configuratorStore.priceRequest,
            fixture_saved_desc: getFixtureDesc(configuratorStore.car),
        };

        const resp = await addOffer(offerObj);

        if (!resp.parsedBody) {
            throw new Error('Error while creating offer');
        }

        Modal.open({
            component: OfferInfoModal,
        });

        return resp.parsedBody;
    }

    return {
        dispatchOffer,
    };
});
