
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import i18n from '@/i18n';

    interface Step {
        index: number;
        label: string;
    }

    @Component
    export default class StepProgress extends Vue {
        @Prop({ default: 1 }) currentStep!: number;

        private steps: Step[] = [
            { index: 0, label: i18n.tc('words.configuration') },
            { index: 1, label: i18n.tc('words.price_in_detail') },
            { index: 2, label: i18n.tc('words.contract_data') },
            { index: 3, label: i18n.tc('words.summary') },
        ];

        stepClasses(i: number) {
            let classes = '';

            if (this.currentStep > i) {
                classes += 'progress__step--complete';
            }

            if (this.currentStep === i) {
                classes += 'progress__step--active';
            }

            return classes;
        }

        get progressClasses() {
            let result = 'progress__bg ';
            if (this.currentStep === this.steps.length) {
                result += 'progress--complete';
            }
            return result;
        }

        get stepWidth() {
            return 100 / (this.steps.length + 1);
        }

        get progressBgWidth() {
            if (this.currentStep > this.steps.length) {
                return '100%';
            } else {
                return `calc(${this.stepWidth * (this.currentStep + 1)}% )`;
            }
        }

        //function to get left distance of a step
        progressStepWidth(step: number) {
            return `calc(${this.stepWidth * (step + 1)}% - 9px)`;
        }
    }
