
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import InfoModal from '@/components/configurator/InfoModal.vue';
    import Tooltip from '@/components/configurator/Tooltip.vue';
    import { Country } from '@/enums/Country';
    import { IInitialPaymentAmount } from '@/interfaces/IInitialPaymentAmount';
    import i18n from '@/i18n';
    import { ICarFull } from '@/interfaces/car/ICarFull';
    import { useGlobalStore } from '@/store/global';
    import { useConfiguratorStore } from '@/store/configurator';
    import { useCustomerStore } from '@/store/customer';

    @Component({
        components: {
            InfoModal,
            Tooltip,
        },
    })
    export default class IPAChooser extends Vue {
        @Prop() car!: ICarFull;

        country: any;
        infoContentIPA = `
        <strong>${i18n.tc('words.initial_payment')}</strong>
            <p>${i18n.tc('messages.config.ipa.tooltip')}</p>
    `;

        infoContentDeposit = `
        <strong>${i18n.tc('words.deposit')}</strong>
            <p>${i18n.tc('messages.config.deposit')}</p>
    `;

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        /**
         * Sort the array for displaying in select box.
         */
        get ipas(): IInitialPaymentAmount[] | undefined {
            const result = [...this.car.initial_payment_amounts];

            // add € 0 ipa to every car (if it's not already existing)
            if (!result.some((ipa) => ipa.amount == 0)) {
                result.push({ amount: 0 });
            }

            return result.sort((a, b) => a.amount - b.amount);
        }

        beforeCreate() {
            this.country = Country;
        }
    }
