
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import VueTippy, { TippyComponent } from 'vue-tippy';

    Vue.use(VueTippy, {
        trigger: 'mouseenter',
        interactive: true,
        boundary: 'viewport',
        popperOptions: {
            placement: 'auto',
        },
        arrow: true,
        arrowType: 'round',
        size: 'large',
        theme: 'light-border',
        allowHTML: true,
        placement: 'left',
        inertia: true,
        zIndex: 9998,
    });

    @Component({
        components: {
            tippy: TippyComponent,
        },
    })
    export default class Tooltip extends Vue {
        @Prop({ default: false }) active!: boolean;

        close() {
            this.$emit('closeModal');
        }
    }
