import { render, staticRenderFns } from "./FixtureUnavailableModalContent.vue?vue&type=template&id=106e020a&scoped=true&"
import script from "./FixtureUnavailableModalContent.vue?vue&type=script&lang=ts&"
export * from "./FixtureUnavailableModalContent.vue?vue&type=script&lang=ts&"
import style0 from "./FixtureUnavailableModalContent.vue?vue&type=style&index=0&id=106e020a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "106e020a",
  null
  
)

export default component.exports