import { defineStore } from 'pinia';
import { useConfiguratorStore } from '@/store/configurator';
import { useCustomerStore } from '@/store/customer';
import {
    ITrialRequestResult,
    ITrialSubscriptionRequest,
} from '@/interfaces/ITrialSubscriptionRequest';
import { addTrial } from '@/api/trial';

export const useTrialStore = defineStore('trial', () => {
    // give access to configuratorStore and customerStore here
    const configuratorStore = useConfiguratorStore();
    const customerStore = useCustomerStore();

    async function dispatchTrial(): Promise<ITrialRequestResult> {
        if (!configuratorStore.priceRequest) {
            throw new Error('Cannot dispatch trial, invalid price request');
        }

        const trialObj: ITrialSubscriptionRequest = {
            customer_email: customerStore.customer.mail_address,
            country: configuratorStore.priceRequest.countryCode,
            data: {
                price_request: configuratorStore.priceRequest,
            },
        };

        // first of all: dispatch customer update
        await customerStore.dispatchCustomerAddOrUpdate();

        const resp = await addTrial(trialObj);

        if (!resp.parsedBody) {
            throw new Error('Error while creating order');
        }

        return resp.parsedBody;
    }

    return {
        dispatchTrial,
    };
});
