
    import { Component, Vue } from 'vue-property-decorator';
    import { isQuizResult } from '@/util/queryfilter';
    import { useGlobalStore } from '@/store/global';
    import InclusiveIcons from '@/components/InclusiveIcons.vue';
    import { Country } from '@/enums/Country';
    import { useSelectorStore } from '@/store/selector';
    import { useCustomerStore } from '@/store/customer';
    import { VehicleState } from '@/enums/VehicleState';

    @Component({
        components: {
            InclusiveIcons,
        },
    })
    export default class InstaHero extends Vue {
        country: any;

        get globalStore() {
            return useGlobalStore();
        }

        get selectorStore() {
            return useSelectorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        get isQuizResultMode(): boolean {
            return isQuizResult(this.$route);
        }

        get quizResultStyles() {
            return this.isQuizResultMode
                ? { '--hero-bg-color': '#4EC644' }
                : { '--hero-bg-color': '#16BAE7' };
        }

        get quizUrl() {
            const globalStore = useGlobalStore();

            return `${process.env.VUE_APP_QUIZ_URL}/${globalStore.country}`;
        }

        get hasOnlyNewCars() {
            if (this.selectorStore.allCars) {
                return !this.selectorStore.allCars?.find(
                    (car) => car.vehicle_state !== VehicleState.NEW_CAR,
                );
            }

            return false;
        }

        scrollToCars() {
            const carContainer = document.getElementsByClassName('columns cars');
            carContainer[0].scrollIntoView({ block: 'start', behavior: 'smooth' });
        }

        beforeCreate() {
            this.country = Country;
        }
    }
