
    import { Component, Vue } from 'vue-property-decorator';
    import { BModalComponent } from 'buefy/types/components';
    import { FixtureLevel } from '@/enums/FixtureLevel';
    import FixtureButton from '@/components/configurator/FixtureButton.vue';

    @Component({
        components: {
            FixtureButton,
        },
    })
    export default class FixtureUnavailableModalContent extends Vue {
        private fixture!: any;

        close() {
            // close the modal window, call close() on $parent, @see https://buefy.org/documentation/modal
            (this.$parent as BModalComponent).close();
        }

        beforeCreate() {
            this.fixture = FixtureLevel;
        }
    }
