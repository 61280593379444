
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import CircleTag from '@/components/tags/CircleTag.vue';
    import { IColor } from '@/interfaces/car/IColor';

    @Component({
        components: {
            CircleTag,
        },
    })
    export default class ColorButton extends Vue {
        @Prop() color!: IColor;
        @Prop() isActive!: boolean;
        @Prop() isIA!: boolean;
        @Prop() isQA!: boolean;

        get colorImgSrc() {
            let imgUrl = this.color.color_image;

            if (process.env.NODE_ENV === 'development') {
                imgUrl = imgUrl.replace('localhost:8001', 'id-cs.com');
            }

            return `https://adhgfzvyfq.cloudimg.io/v7/${imgUrl}?w=70`;
        }
    }
