import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from '@/components/NotFound.vue';
import SelectorView from '@/views/SelectorView.vue';
import ConfiguratorView from '@/views/ConfiguratorView.vue';
import CarDesktopView from '@/views/CarDesktopView.vue';
import Configurator from '@/components/Configurator.vue';
import OrderView from '@/views/OrderView.vue';
import CustomerForm from '@/components/order/CustomerForm.vue';
import PriceDetailPage from '@/components/order/PriceDetailPage.vue';
import Summary from '@/components/order/Summary.vue';
import Congratulations from '@/components/order/Congratulations.vue';
import { useGlobalStore } from '@/store/global';

const routes = [
    {
        path: '/',
        name: 'selector',
        component: SelectorView,
    },
    {
        path: '/configure/:slug',
        component: ConfiguratorView,
        children: [
            {
                path: '',
                name: 'configure',
                components: {
                    desktopview: CarDesktopView,
                    sidebar: Configurator,
                },
            },
        ],
    },
    {
        path: '/order',
        component: OrderView,
        children: [
            {
                path: 'price-detail',
                name: 'pricedetail',
                components: {
                    main: PriceDetailPage,
                },
            },
            {
                path: 'customer-form',
                name: 'customerform',
                components: {
                    main: CustomerForm,
                },
            },
            {
                path: 'summary',
                name: 'summary',
                components: {
                    main: Summary,
                },
            },
            {
                path: 'congratulations',
                name: 'congratulations',
                components: {
                    main: Congratulations,
                },
            },
        ],
    },
    {
        path: '*',
        name: 'not-found',
        component: NotFound,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    const globalStore = useGlobalStore();
    if (from.name) {
        globalStore.lastURL = from.name;
    }
    next();
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        const globalStore = useGlobalStore();

        Vue.prototype.$posthog.capture('$pageview', {
            $current_url: process.env.VUE_APP_BASE_URL + to.fullPath,
            current_country: globalStore.country,
        });
    });
});

export default router;
