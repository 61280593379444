export enum CompanyFleetSize {
    SIZE1 = 1,
    SIZE2 = 2,
    SIZE3 = 3,
    SIZE4 = 4,
}

/**
 * Map the CompanyFleetSize enum to german text.
 *
 * @TODO: map to english text and translate with i18n
 *
 * @param fleetSize
 * @return text
 */
export function getCompanyFleetSizeText(fleetSize: CompanyFleetSize) {
    switch (fleetSize) {
        case CompanyFleetSize.SIZE1:
            return '1_5_vehicles';
        case CompanyFleetSize.SIZE2:
            return '6_20_vehicles';
        case CompanyFleetSize.SIZE3:
            return '21_50_vehicles';
        case CompanyFleetSize.SIZE4:
            return '50_vehicles';
    }
}
