import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ICustomer } from '@/interfaces/ICustomer';
import { addOrUpdateCustomer } from '@/api/customer';
import { SemiLoginType } from '@/enums/SemiLoginType';

export const useCustomerStore = defineStore(
    'customer',
    () => {
        const customer = ref<ICustomer>({
            semilogin_type: SemiLoginType.MAIL_ADDRESS,
            is_company: undefined,
            fleet_size: undefined,
            mail_address: '',
            news_opt_in: false,
            company: undefined,
            gender: undefined,
            first_name: undefined,
            last_name: undefined,
            phone_number: undefined,
            street: undefined,
            zip_code: undefined,
            city: undefined,
            sales_channel: undefined,
        });

        /**
         * Reset the customer data completely.
         */
        function resetCustomer() {
            customer.value.semilogin_type = SemiLoginType.MAIL_ADDRESS;
            customer.value.initial_country_code = undefined;
            customer.value.is_company = undefined;
            customer.value.fleet_size = undefined;
            customer.value.mail_address = '';
            customer.value.news_opt_in = false;
            customer.value.company = undefined;
            customer.value.gender = undefined;
            customer.value.first_name = undefined;
            customer.value.last_name = undefined;
            customer.value.phone_number = undefined;
            customer.value.street = undefined;
            customer.value.zip_code = undefined;
            customer.value.city = undefined;
            customer.value.sales_channel = undefined;
        }

        /**
         * Dispatch the Customer AddOrUpdate.
         */
        async function dispatchCustomerAddOrUpdate() {
            const res = await addOrUpdateCustomer(customer.value);

            if (!res.parsedBody) {
                throw new Error('Error while updating or adding customer data');
            }
        }

        return {
            customer,
            resetCustomer,
            dispatchCustomerAddOrUpdate,
        };
    },
    {
        /**
         *  Establish the pinia persistent storage.
         *  Store the following in localStorage:
         *
         *  - customer
         *
         *  These fields should 'survive' a page refresh.
         *
         *  @see https://github.com/prazdevs/pinia-plugin-persistedstate
         */
        persist: {
            paths: ['customer'],
        },
    },
);
