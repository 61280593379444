var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('section',{staticClass:"cooperation-hero scharinger"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"logo-box"},[_c('span',{domProps:{"innerHTML":_vm._s(
                        require('!html-loader!@/assets/img/cooperations/scharinger/logo.svg')
                    )}}),_c('h2',[_vm._v("Die Straße zur CO2 Neutralität")])]),_c('div',{staticClass:"icon-line"},[_c('span',{domProps:{"innerHTML":_vm._s(
                        require('!html-loader!@/assets/img/cooperations/scharinger/icons/zero-co2.svg')
                    )}}),_c('span',{domProps:{"innerHTML":_vm._s(
                        require('!html-loader!@/assets/img/cooperations/scharinger/icons/energy-efficiency.svg')
                    )}}),_c('span',{domProps:{"innerHTML":_vm._s(
                        require('!html-loader!@/assets/img/cooperations/scharinger/icons/subsidies.svg')
                    )}}),_c('span',{domProps:{"innerHTML":_vm._s(
                        require('!html-loader!@/assets/img/cooperations/scharinger/icons/training.svg')
                    )}}),_c('span',{domProps:{"innerHTML":_vm._s(
                        require('!html-loader!@/assets/img/cooperations/scharinger/icons/hydrogen.svg')
                    )}})])])]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container cooperation"},[_c('h1',{staticClass:"title"},[_vm._v("E-Mobilität")]),_c('p',[_vm._v(" Wir sind ein Dienstleistungsunternehmen, das mit Kunden gemeinsam einen Zero Emission-Weg plant und Sie bis zur Realisierung begleitet. Mit unserem EASING Produkt, welches wir gemeinsam mit INSTADRIVE entwickelt haben, können wir eine maßgeschneiderte Mobilitätslösung anbieten. Der Fokus liegt hier bei mittleren und kleineren Betrieben, aber auch Einzelunternehmer und private Haushalte können mit unseren \"Sorglos Paketen\" Energie und Kosten sparen. Passende Green Finance-Konzepte können dabei bei der Umsetzung zur CO2-Neutralität aktiv unterstützen und umgesetzt werden. ")])])
}]

export { render, staticRenderFns }