
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import { Gender } from '@/enums/Gender';
    import { addOrUpdateCustomer } from '@/api/customer';
    import { GlobalStep } from '@/enums/GlobalStep';
    import { Country } from '@/enums/Country';
    import { useGlobalStore } from '@/store/global';
    import { useCustomerStore } from '@/store/customer';
    import { useConfiguratorStore } from '@/store/configurator';
    import { useOrderStore } from '@/store/order';
    import { useSemiLoginStore } from '@/store/semilogin';
    import { addEventInteraction } from '@/api/instalytics';
    import { IMake } from '@/interfaces/car/IMake';
    import { EventType } from '@/interfaces/instalytics/interaction';
    import WithdrawalBadge from '../WithdrawalBadge.vue';
    import BottomPriceBar from '@/components/BottomPriceBar.vue';
    import CalendlyWidget from '../CalendlyWidget.vue';

    @Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            WithdrawalBadge,
            BottomPriceBar,
            CalendlyWidget,
        },
    })
    export default class CustomerForm extends Vue {
        gender: any;
        country: any;
        isLoading = false;

        /**
         * Trigger a form input: add interaction to instalytics.
         */
        triggerFormInputInteraction() {
            if (!this.globalStore.country || !this.configuratorStore.car) {
                throw new Error('country or car undefined');
            }

            addEventInteraction({
                email: this.customerStore.customer.mail_address,
                country: this.globalStore.country,
                make: {
                    id: (this.configuratorStore.car.model_variant.model.make as IMake).id,
                    name: (this.configuratorStore.car.model_variant.model.make as IMake).name,
                },
                car: {
                    id: this.configuratorStore.car.id,
                    name: `${this.configuratorStore.car.model_variant.model.name} ${
                        this.configuratorStore.car.model_variant.name || ''
                    }`,
                    model_year: this.configuratorStore.car.model_year,
                    vehicle_state: this.configuratorStore.car.vehicle_state,
                },
                type: EventType.CUSTOMER_FORM_INPUT,
            });
        }

        /**
         * Form Input Change Event Handler
         *
         * @param event
         */
        onFormInputChange(event: Event) {
            // get element from event
            const elem = event.target as HTMLInputElement;

            if (elem.value) {
                // element has value, trigger the interaction
                this.triggerFormInputInteraction();
            }
        }

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        get customerStore() {
            return useCustomerStore();
        }

        get orderStore() {
            return useOrderStore();
        }

        get semiLoginStore() {
            return useSemiLoginStore();
        }

        @Watch('customerStore.customer', { deep: true })
        onCustomerChanged() {
            if (!this.globalStore.country || !this.configuratorStore.car) {
                throw new Error('Country or car undefined.');
            }
        }

        goBack() {
            const configuratorStore = useConfiguratorStore();

            if (configuratorStore.car?.url_slug) {
                this.$router.push({
                    name: 'pricedetail',
                });
            }
        }

        async saveCustomerData() {
            this.isLoading = true;

            // Send empty string to service when saving this form and no value is given.
            if (this.customerStore.customer.company === undefined) {
                this.customerStore.customer.company = '';
            }

            const aucResp = await addOrUpdateCustomer(this.customerStore.customer);

            if (!aucResp.parsedBody) {
                throw new Error('cannot retrieve customer add or update response.');
            }

            this.$router.push({ name: 'summary' });
        }

        mounted() {
            this.globalStore.currentStep = GlobalStep.CUSTOMER_DATA;
        }

        beforeCreate() {
            this.gender = Gender;
            this.country = Country;
        }
    }
