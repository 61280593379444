var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"tag",class:{
        checked: _vm.tagtype === 'checked',
        flash: _vm.tagtype === 'flash',
        dnd: _vm.tagtype === 'dnd',
        close: _vm.tagtype === 'close',
        'fast-forward': _vm.tagtype === 'fast-forward',
    }},[(_vm.tagtype === 'checked')?_c('span',{attrs:{"title":"Check Icon"},domProps:{"innerHTML":_vm._s(require('!html-loader!@/assets/img/checklist-icon.svg'))}}):_vm._e(),(_vm.tagtype === 'flash')?_c('span',{attrs:{"title":"Flash Icon"},domProps:{"innerHTML":_vm._s(require('!html-loader!@/assets/img/ios-flash.svg'))}}):_vm._e(),(_vm.tagtype === 'dnd')?_c('span',{attrs:{"title":"DND Icon"},domProps:{"innerHTML":_vm._s(require('!html-loader!@/assets/img/do-not-disturb.svg'))}}):_vm._e(),(_vm.tagtype === 'fast-forward')?_c('span',{attrs:{"title":"Fast Forward Icon"},domProps:{"innerHTML":_vm._s(require('!html-loader!@/assets/img/fast-forward.svg'))}}):_vm._e(),(_vm.tagtype === 'close')?_c('span',{attrs:{"title":"Close Icon"},domProps:{"innerHTML":_vm._s(require('!html-loader!@/assets/img/close.svg'))}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }