export enum AvailabilitySteps {
    IMMEDIATELY = 1,
    M3 = 2,
    M6 = 3,
    M12 = 4,
    ALL = 5,
}

/**
 * Get the number of days from AvailabilitySteps enum.
 *
 * @param avail
 * @return days number of days
 */
export function get_availability_days(avail: AvailabilitySteps): number {
    if (avail === AvailabilitySteps.ALL || avail === AvailabilitySteps.IMMEDIATELY) {
        throw new Error('Cannot get days from AvailabilitySteps (all or immediately)');
    }

    if (avail === AvailabilitySteps.M3) {
        return 3 * 30;
    }

    if (avail === AvailabilitySteps.M6) {
        return 6 * 30;
    }

    if (avail === AvailabilitySteps.M12) {
        return 12 * 30;
    }

    throw new Error('Cannot get days from AvailabilitySteps');
}
