
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { Country } from '@/enums/Country';
    import { normalizeSvgViewBoxes } from '@/util/misc';
    import { useGlobalStore } from '@/store/global';

    @Component
    export default class InclusiveIcons extends Vue {
        @Prop({ default: false }) wrap;
        @Prop({ default: false }) alignLeft;
        country: any;

        get globalStore() {
            return useGlobalStore();
        }

        mounted() {
            this.$nextTick(function () {
                // Code that will run only after the
                // entire view has been rendered
                normalizeSvgViewBoxes(this.$el.querySelectorAll('span.icon > svg'));
            });
        }

        beforeCreate() {
            this.country = Country;
        }
    }
