import { Country } from '@/enums/Country';
import i18n from '@/i18n';

/**
 * Return the appropriate currency symbol by given country.
 *
 * @param c country
 */
export function getCurrSign(c: Country): string {
    if (c === Country.CH) {
        return 'CHF';
    }

    return '€';
}

/**
 * Build/Get the price display format, e.g. € 100 or 100 CHF.
 *
 * @param c country
 * @param amount price
 */
export function getPriceDisplay(c: Country, amount: number) {
    const curr = getCurrSign(c);
    const amountString = Math.round(amount).toLocaleString(i18n.locale);

    if (c === Country.CH) {
        return `${amountString} ${curr}`;
    }

    return `${curr} ${amountString}`;
}

/**
 * Get the tax name according to country.
 *
 * @param country country object
 * @return name
 */
export function getTaxName(country: Country): string {
    if (country === Country.AT || country === Country.DE) {
        return 'USt';
    }

    if (country === Country.CH) {
        return 'MWST';
    }

    if (country === Country.FR) {
        return 'TVA';
    }

    return 'incl. VAT';
}
