import {
    IConfiguratonInteraction,
    IEventInteraction,
    IStepNavigationInteraction,
} from '@/interfaces/instalytics/interaction';
import { HttpResponse, post } from '@/api/base';

const api_url = '/api/v1/interactions';

/**
 * Add a configuration interaction to instalytics.
 *
 * @param data interaction data
 * @return created data
 */
export async function addConfigurationInteraction(
    data: IConfiguratonInteraction,
): Promise<HttpResponse<IConfiguratonInteraction>> {
    return await post<IConfiguratonInteraction>(
        `${process.env.VUE_APP_INSTALYTICS_HOST}${api_url}/configuration`,
        data,
        false,
    );
}

/**
 * Add a stepnavigation interaction to instalytics.
 *
 * @param data interaction data
 * @return created data
 */
export async function addStepNavigationInteraction(
    data: IStepNavigationInteraction,
): Promise<HttpResponse<IStepNavigationInteraction>> {
    return await post<IStepNavigationInteraction>(
        `${process.env.VUE_APP_INSTALYTICS_HOST}${api_url}/step-navigation`,
        data,
        false,
    );
}

/**
 * Add an event interaction to instalytics.
 *
 * @param data interaction data
 * @return created data
 */
export async function addEventInteraction(
    data: IEventInteraction,
): Promise<HttpResponse<IEventInteraction>> {
    return await post<IEventInteraction>(
        `${process.env.VUE_APP_INSTALYTICS_HOST}${api_url}/event`,
        data,
        false,
    );
}
