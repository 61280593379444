import { HttpResponse, post } from '@/api/base';
import { IOffer, IOfferResult } from '@/interfaces/IOffer';

/**
 * Add an offer to IC Offerstore.
 *
 * @param offer offer data
 */
export async function addOffer(offer: IOffer): Promise<HttpResponse<IOfferResult>> {
    return await post<IOfferResult>(
        `${process.env.VUE_APP_IC_SERVICE_HOST}/offerstore/offer/`,
        offer,
    );
}
