import { defineStore } from 'pinia';
import { IOrder, IOrderResult } from '@/interfaces/order/IOrder';
import { ref } from 'vue';
import { IOrderData } from '@/interfaces/order/IOrderData';
import { addOrder } from '@/api/order';
import { useConfiguratorStore } from '@/store/configurator';
import { useCustomerStore } from '@/store/customer';
import { getFixtureDesc } from '@/util/car';

export const useOrderStore = defineStore('order', () => {
    // give access to configuratorStore and customerStore here
    const configuratorStore = useConfiguratorStore();
    const customerStore = useCustomerStore();

    // order data
    const data = ref<IOrderData | undefined>(undefined);

    // additional infos ("Vermerk")
    const additionalInfo = ref<string | undefined>(undefined);

    /**
     * Create the order confirmation and send it to api endpoint.
     */
    async function dispatchOrder(): Promise<IOrderResult> {
        if (
            !configuratorStore.priceResult ||
            !configuratorStore.priceRequest ||
            !configuratorStore.car ||
            !configuratorStore.config.color
        ) {
            throw new Error('Cannot dispatch order, invalid price and car data.');
        }

        data.value = {
            price_request: configuratorStore.priceRequest,
            price_result: configuratorStore.priceResult,
            fixture_data: getFixtureDesc(configuratorStore.car),
        };

        const orderObj: IOrder = {
            customer_email: customerStore.customer.mail_address,
            data: data.value,
            additional_infos: additionalInfo.value,
        };

        const resp = await addOrder(orderObj);

        if (!resp.parsedBody) {
            throw new Error('Error while creating order');
        }

        return resp.parsedBody;
    }

    return {
        additionalInfo,
        dispatchOrder,
    };
});
