
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import CircleTag from '@/components/tags/CircleTag.vue';
    import ColorButton from '@/components/configurator/ColorButton.vue';
    import { IColor } from '@/interfaces/car/IColor';
    import { ICarFull } from '@/interfaces/car/ICarFull';
    import { useGlobalStore } from '@/store/global';
    import { useConfiguratorStore } from '@/store/configurator';
    import InfoModal from '@/components/configurator/InfoModal.vue';
    import Tooltip from '@/components/configurator/Tooltip.vue';
    import i18n from '@/i18n';
    import { isDeliveryTimeInInterval } from '@/util/misc';

    @Component({
        components: {
            InfoModal,
            Tooltip,
            ColorButton,
            CircleTag,
        },
    })
    export default class ColorChooser extends Vue {
        @Prop() car!: ICarFull;
        @Prop({ default: true }) isLoading!: boolean;

        colorHintContent = `<div>${i18n.tc('messages.config.color_hint_text')}</div>`;

        get globalStore() {
            return useGlobalStore();
        }

        get configuratorStore() {
            return useConfiguratorStore();
        }

        colorButtonClicked(color: IColor) {
            this.configuratorStore.config.color = color;
        }

        isIA(c: IColor) {
            if (!this.car) {
                throw new Error('car is undefined');
            }

            if (!this.configuratorStore.config.fixture) {
                throw new Error('configFixture is undefined');
            }

            return isDeliveryTimeInInterval(
                c,
                this.configuratorStore.config.fixture,
                this.car,
                this.globalStore.iaInterval,
            );
        }
    }
