export enum MiPerYear {
    MI_5000 = 5,
    MI_8000 = 8,
    MI_10000 = 10,
    MI_12000 = 12,
    MI_15000 = 15,
    MI_20000 = 20,
    MI_25000 = 25,
    MI_30000 = 30,
}
