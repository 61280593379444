
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { Country } from '@/enums/Country';
    import { useGlobalStore } from '@/store/global';

    @Component
    export default class CountrySwitcher extends Vue {
        @Prop({ default: true }) showCode!: boolean;

        get globalStore() {
            return useGlobalStore();
        }

        get countryFlag() {
            return require(`!html-loader!@/assets/img/flags/${this.globalStore.country}.svg`);
        }

        get spanClass() {
            return !this.$screen.desktop ? 'small' : '';
        }

        get allCountriesExceptCurrent() {
            return Object.values(Country).filter((el) => el != this.globalStore.country);
        }
    }
